import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Navigation {
    isOpen: boolean;
}

const initialState: Navigation = {
    isOpen: false,
};

export const navigation = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setOpen(state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
    },
});

export const navigationActions = navigation.actions;

export default navigation.reducer;
