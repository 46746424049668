import { IReplenishment } from '../interfaces';

const replenishments: IReplenishment[] = [
    {
        id: 1,
        name: 'Tonkeeper',
        image: 'tonkeeper.png',
        path: '/tonkeeper',
    },
    {
        id: 2,
        name: 'Cryptomus',
        image: 'cryptomus.png',
        path: '/cryptomus/steam/login',
    },
    {
        id: 3,
        name: 'Aaio',
        image: 'aaio.png',
        path: '/aaio/steam/login',
    },
];

export default replenishments;
