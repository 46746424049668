import { Button, Text } from '@chakra-ui/react';

interface Props {
    name: string;
    [x: string]: any;
}

export default function BuyButton({ name, ...props }: Props) {
    return (
        <Button
            variant="solid"
            color="white"
            background="rgba(245, 150, 34, 0.9)"
            fontSize="16px"
            fontWeight="500"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="12px"
            width="100%"
            padding="25px 30px"
            {...props}>
            <Text>{name}</Text>
        </Button>
    );
}
