import {
    Box,
    Button,
    Container,
    Stack,
    StackDivider,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../../../styles/shine.css';

import Colors from '../../../config/colors';
import { FaChevronRight } from 'react-icons/fa';
import {
    useCreateAaioInvoiceMutation,
    useCreateCryptomusInvoiceMutation,
    useGetCurrenciesQuery,
    useGetRateQuery,
} from '../../../redux/platimarket/platimarket.api';
import { useEffect } from 'react';

export default function AaioSteamConfirmPage() {
    const { login, amount } = useAppSelector((state) => state.replenishment.steam);

    const { data: currenciesData } = useGetCurrenciesQuery();

    const currencies = currenciesData?.data ?? [];

    const currency = currencies.find((item) => item.currency_pair.split(':')[1].includes('RUB'));

    const closePrice = currency?.close_price ?? 1;

    const comissionPercent = +process.env.REACT_APP_COMISSION_PERCENT!;

    const comission = (amount / 100) * comissionPercent + closePrice;

    const totalAmount = amount + comission;

    const [
        fetchInvoice,
        {
            data: invoice,
            isSuccess: isSuccessInvoice,
            isError: isErrorInvoice,
            error: errorInvoice,
        },
    ] = useCreateAaioInvoiceMutation();

    const createInvoice = () => {
        fetchInvoice({ amount, currency: 'RUB', username: login! });
    };

    useEffect(() => {
        if (isSuccessInvoice && invoice) {
            const paymentURL = invoice.paymentURL;

            window.location.href = paymentURL;
        }
    }, [isSuccessInvoice]);

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="4">
                <Box
                    display="flex"
                    gap="4"
                    alignItems="center"
                    backgroundColor={Colors.lightGrey}
                    padding="16px"
                    borderRadius="10px">
                    <LazyLoadImage
                        effect="blur"
                        src={process.env.PUBLIC_URL + '/images/replenishment/steamSquare.jpeg'}
                        style={{ height: '100px', borderRadius: '10px' }}
                    />
                    <Stat>
                        <StatNumber>{amount} RUB</StatNumber>
                        <StatHelpText opacity="1">Пополнение баланса аккаунта Steam</StatHelpText>
                        <StatLabel>Steam, Valve Corporation</StatLabel>
                    </Stat>
                </Box>
                <Box
                    backgroundColor={Colors.lightGrey}
                    fontWeight="500 "
                    padding="16px"
                    borderRadius="10px">
                    <Stack divider={<StackDivider />} spacing="4" fontWeight="600">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text>Логин Steam</Text>
                            <Box
                                display="flex"
                                alignItems="center"
                                gap="2"
                                opacity="0.8"
                                as={Link}
                                to={'/tonkeeper/steam/login'}>
                                <Text>{login}</Text>
                                <FaChevronRight fontSize="16px" />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text>Валюта и курс Steam</Text>

                            <Box
                                display="flex"
                                alignItems="center"
                                gap="2"
                                opacity="0.8"
                                as={Link}
                                to={'/currency'}>
                                <Text>RUB {currency?.close_price ? '/ ' + closePrice : ''}</Text>
                                <FaChevronRight fontSize="16px" />
                            </Box>
                        </Box>

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text>Комиссия</Text>
                            <Text opacity="0.8">{comission.toFixed(2)} RUB</Text>
                        </Box>

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Text>Итого</Text>
                            <Text opacity="0.8">{totalAmount.toFixed(2)} RUB</Text>
                        </Box>
                    </Stack>
                </Box>
                <Box
                    position="fixed"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bottom="0"
                    zIndex="3"
                    left="0"
                    right="0"
                    padding="20px"
                    flexDirection="column"
                    gap="3">
                    <Button
                        onClick={() => createInvoice()}
                        className="shine"
                        variant="solid"
                        color="white"
                        background={Colors.blue}
                        fontSize="16px"
                        fontWeight="500"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="12px"
                        width="100%"
                        padding="25px 0px"
                        borderRadius="8px">
                        <Text>Оплатить {totalAmount.toFixed(2)} RUB</Text>
                    </Button>
                    <Text fontSize="12px" textAlign="center">
                        Нажимая «Оплатить» вы подтверждаете, что указали правильный логин Steam
                    </Text>
                </Box>
            </Box>
        </Container>
    );
}
