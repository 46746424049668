import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Search {
    name: string;
}

const initialState: Search = {
    name: '',
};

export const search = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearch(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },
    },
});

export const searchActions = search.actions;

export default search.reducer;
