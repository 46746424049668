import { Box, Flex, Text, useClipboard, useToast } from '@chakra-ui/react';
import ProfileAvatar from '../profileAvatar/ProfileAvatar';
import Colors from '../../config/colors';
import { useGetUserPhotoQuery } from '../../redux/platimarket/platimarket.api';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';

interface Props {
    userId: number;
    name: string;
    login?: string;
}

export default function About({ userId, name, login }: Props) {
    const { isLoading, isError, data } = useGetUserPhotoQuery({ userId });
    const { onCopy, hasCopied } = useClipboard(login ?? '');
    const toast = useToast();

    useEffect(() => {
        if (hasCopied) {
            toast({
                description: 'Логин скопирован',
                status: 'info',
                duration: 1000,
                render: () => (
                    <Flex
                        m={3}
                        color="white"
                        p={3}
                        bg={Colors.blue}
                        borderRadius="12px"
                        gap="2"
                        alignItems="center">
                        <CheckIcon />
                        <Box>Логин успешно скопирован</Box>
                    </Flex>
                ),
            });
        }
    }, [hasCopied]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="6px"
            padding="15px 0px">
            <ProfileAvatar src={data?.url ?? ''} />
            <Text fontSize="15px">{name}</Text>
            {login && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="2"
                    color={hasCopied ? Colors.blue : Colors.darkGrey}
                    onClick={onCopy}>
                    <Text fontSize="12px">@{login}</Text>
                    <CopyIcon />
                </Box>
            )}
        </Box>
    );
}
