import {
    Alert,
    AlertIcon,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalFooter,
    Text,
    Textarea,
} from '@chakra-ui/react';
import Colors from '../../../config/colors';
import { useRef } from 'react';
import { Select } from 'chakra-react-select';

interface ICategory {
    label: string;
    value: number;
}

interface Props {
    productName: string;
    setProductName: (email: string) => void;
    errorMessage: string | null;
    category: ICategory;
    categoryList: ICategory[];
    selectCategory: (category: any) => void;
    setMessage: (message: string) => void;
    sendUserMessage: () => void;
}

export default function SupportDialog({
    productName,
    setProductName,
    errorMessage,
    category,
    categoryList,
    selectCategory,
    setMessage,
    sendUserMessage,
}: Props) {
    const initialRef = useRef(null);

    return (
        <>
            <ModalBody pb={6} display="flex" flexDirection="column" gap="5">
                <FormControl isRequired>
                    <FormLabel>Какой товар Вы ищите ?</FormLabel>
                    <Input
                        ref={initialRef}
                        placeholder="Название желаемого товара"
                        value={productName}
                        isDisabled={false}
                        onChange={(e) => setProductName(e.target.value)}
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Категория</FormLabel>
                    {categoryList?.length && (
                        <Select
                            isSearchable={false}
                            onChange={(option) => selectCategory(option)}
                            options={categoryList}
                            noOptionsMessage={({ inputValue }) => (
                                <Box>
                                    <Text>
                                        Такой категории &nbsp;
                                        <span style={{ fontWeight: '600' }}>«{inputValue}»</span>
                                        &nbsp; не существует
                                    </Text>
                                </Box>
                            )}
                            value={category}
                        />
                    )}
                </FormControl>
                <FormControl>
                    <FormLabel>Сообщение</FormLabel>
                    <Textarea
                        placeholder="Задайте нам вопрос по товару ..."
                        height="150px"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </FormControl>
                {errorMessage && (
                    <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                    </Alert>
                )}
                {!errorMessage && (
                    <Alert status="info">
                        <AlertIcon />
                        Отвечаем в течение 24 часов
                    </Alert>
                )}
            </ModalBody>

            <ModalFooter display="flex" gap="7px" justifyContent="space-between">
                {/* <Loader size="md" /> */}
                <Box></Box>
                <Box display="flex" gap="3">
                    <Button
                        bg={Colors.orange}
                        color="#fff"
                        fontWeight="500"
                        onClick={sendUserMessage}>
                        Отправить
                    </Button>
                    <Button fontWeight="500">Закрыть</Button>
                </Box>
            </ModalFooter>
        </>
    );
}
