import { Container } from '@chakra-ui/react';
import SearchInput from '../searchInput/SearchInput';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useActions } from '../../hooks/actions';
import findRoute from '../../utils/findRoute';

export default function Header() {
    const location = useLocation();
    const pathName = location.pathname;

    // const showSearchRoutes = routes.filter((route) => route.showSearch);

    // const findedRoute = showSearchRoutes.find((route) =>
    //     route.path.startsWith('/' + pathName.split('/')[1]),
    // );
    const findedRoute = findRoute(pathName);

    const { setSearch } = useActions();

    useEffect(() => {
        window.scrollTo(0, 0);
        setSearch('');
    }, [pathName]);

    return (
        <Container display="flex" alignItems="center" height="100%">
            {findedRoute?.showSearch ? (
                <SearchInput showBackButton={pathName !== '/catalog'} />
            ) : (
                !findedRoute?.isHideBreadcrumb && <Breadcrumb path={pathName} />
            )}
        </Container>
    );
}
