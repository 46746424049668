import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
    useGetCategoriesQuery,
    useSendMessageMutation,
} from '../../redux/platimarket/platimarket.api';

import { useAppSelector } from '../../hooks/redux';
import { ICategory } from '../../interfaces';
import SupportDialog from './components/SupportDialog';
import SuccessDialog from './components/SuccessDialog';
import { useActions } from '../../hooks/actions';
import { useParams } from 'react-router-dom';
import { useTelegram } from '../../hooks/useTelegram';

interface Props {
    isOpen: boolean;
    setIsOpenModal: (isOpen: boolean) => void;
}

export default function SupportModal({ isOpen, setIsOpenModal }: Props) {
    const initialRef = React.useRef(null);
    const params = useParams();
    const { user, onClose } = useTelegram();

    const categoryId = Number(params.categoryId);

    const searchValue = useAppSelector((state) => state.search.name);
    const { setSearch } = useActions();

    const [productName, setProductName] = useState('');
    const [message, setMessage] = useState('');
    const [category, setCategory] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const {
        isLoading: isLoadingCategories,
        isError: isCategoriesError,
        isSuccess: isCategoriesSuccess,
        data: categories,
    } = useGetCategoriesQuery({
        orderBy: 'position',
        sortBy: 'ASC',
    });

    const [sendMessage, { isLoading: isLoadingSendMessage, isSuccess: isSuccessSendMessage }] =
        useSendMessageMutation();

    const categoryList = categories?.map((category: ICategory) => ({
        label: category.name,
        value: category.id,
    }));

    const closeModal = () => {
        setIsOpenModal(false);
        setSearch('');
    };

    const sendUserMessage = () => {
        if (productName.length < 2) {
            setErrorMessage('Введите корректное название товара');
        } else if (message.length && message.length < 10) {
            setErrorMessage('Сообщение не может быть менее 10 символов');
        } else {
            sendMessage({ productName, message, categoryId: category.value, supportStatusId: 1, username: user.username });
        }
    };

    const selectCategory = (categoryId: number) => {
        setCategory(categoryList?.find((category) => category.value === categoryId));
    };

    useEffect(() => {
        setErrorMessage('');
    }, [productName, message]);

    useEffect(() => {
        setProductName(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (categories?.length) {
            let currentCategory = { label: categories[0].name, value: categories[0].id };

            if (categoryId) {
                const findedCategory = categories.find((category) => category.id === categoryId);

                currentCategory = {
                    label: findedCategory?.name!,
                    value: findedCategory?.id!,
                };
            }

            setCategory(currentCategory);
        }
    }, [isLoadingCategories]);

    return (
        <>
            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent m="8">
                    <ModalHeader fontWeight="500">Поддержка</ModalHeader>
                    <ModalCloseButton />
                    {isSuccessSendMessage ? (
                        <SuccessDialog closeModal={closeModal} />
                    ) : (
                        <SupportDialog
                            productName={productName}
                            setProductName={setProductName}
                            errorMessage={errorMessage}
                            category={category}
                            categoryList={categoryList ?? []}
                            selectCategory={selectCategory}
                            setMessage={setMessage}
                            sendUserMessage={sendUserMessage}
                        />
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
