import { Box, Container, Image, Text } from '@chakra-ui/react';

import replenishments from '../../../config/replenishments';
import { Link } from 'react-router-dom';
import { useActions } from '../../../hooks/actions';
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Replenishment() {
    const { resetReplenishment } = useActions();

    useEffect(() => {
        resetReplenishment();
    }, []);

    return (
        <Container display="flex" alignItems="center" height="100vh" justifyContent="center">
            <Box
                paddingBottom="230px"
                display="flex"
                flexDirection="column"
                gap="28px"
                textAlign="center"
                fontSize="16px">
                <Text>Выберите способ пополненения Steam</Text>
                <Box display="flex" gap="16px" fontSize="12px" justifyContent="center">
                    {replenishments.map((item) => (
                        <Link to={item.path}>
                            <Box display="flex" flexDirection="column" gap="9px">
                                <LazyLoadImage
                                    effect="blur"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/images/replenishment/' +
                                        item.image
                                    }
                                    style={{ height: '100px', borderRadius: '10px' }}
                                />
                                <Text fontSize="14px">{item.name}</Text>
                            </Box>
                        </Link>
                    ))}
                </Box>
            </Box>
        </Container>
    );
}
