export const cardBasicVariants = {
    hidden: {
        opacity: 0,
    },
    visible: (index: number) => ({
        opacity: 1,
        transition: {
            delay: 0.08 * index,
        },
    }),
};

export const cardLazyVariants = {
    hidden: {
        opacity: 0,
        y: 10,
    },
    visible: (index: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.08 * index,
        },
    }),
};
