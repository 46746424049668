import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Replenishment {
    steam: {
        [index: string]: any;
        login: string | null;
        currency: string;
        amount: number;
        inTON: number;
        isSave: boolean;
        isSwap: boolean;
    };
}

interface ReplenishmentPayload {
    field: string;
    value: any;
}

const initialState: Replenishment = {
    steam: {
        isSave: true,
        login: null,
        currency: 'RUB',
        amount: 0,
        inTON: 0,
        isSwap: false,
    },
};

export const replenishment = createSlice({
    name: 'replenishment',
    initialState,
    reducers: {
        setFieldSteamReplenishment(state, action: PayloadAction<ReplenishmentPayload>) {
            const { field, value } = action.payload;

            if (state.steam) {
                state.steam[field] = value;
            }
        },
        resetReplenishment: () => initialState,
    },
});

export const replenishmentActions = replenishment.actions;

export default replenishment.reducer;
