import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { tonConnectUIProviderConfig } from './config/tonconnect';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>,

    <Provider store={store}>
        <TonConnectUIProvider {...tonConnectUIProviderConfig}>
            <App />
        </TonConnectUIProvider>
    </Provider>,
);
