import { Box, Card, CardBody, Image, Text } from '@chakra-ui/react';
import { ICategory } from '../../interfaces';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PRODUCTS_CATEGORY_IMAGES_PATH } from '../../config/images';

interface Props {
    productsCategory: ICategory;
}

export default function ProductCard({ productsCategory }: Props) {
    return (
        <Card boxShadow="rgba(149, 157, 165, 0.25) 0px 3px 12px" borderRadius="10px" height="100%">
            <CardBody display="flex" flexDirection="column" padding="0px">
                <LazyLoadImage
                    effect="blur"
                    src={PRODUCTS_CATEGORY_IMAGES_PATH + productsCategory.image}
                    style={{
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        height: '250px',
                    }}
                />
                <Box padding="12px">
                    <Text fontSize="14px">{productsCategory.name}</Text>
                </Box>
            </CardBody>
        </Card>
    );
}
