import { useActions } from '../hooks/actions';
import { AuthState } from '../redux/platimarket/slices/authSlice';
import checkTokenExpiration from '../utils/checkTokenExpiration';

export const useUser = () => {
    const { setUser } = useActions();

    let currentUser: AuthState = { accessToken: null };
    const savedUser = localStorage.getItem('user');
    let isAuth = false;

    if (savedUser) {
        currentUser = { ...JSON.parse(savedUser) };
        const accessToken = currentUser.accessToken;

        if (accessToken) {
            isAuth = checkTokenExpiration(accessToken);
        }
    }

    return { user: currentUser, isAuth, setUser };
};
