export const calcComission = (closePrice: number, comissionPercent: number) => {
    return (closePrice / 100) * comissionPercent + closePrice;
};

export const calcMinAmount = (closePrice: number, comission: number) => {
    return comission + (closePrice / 100) * 10;
};

export const calcInTON = (closePrice: number, amount: number, tonExchangeRate: number) => {
    return amount / closePrice / tonExchangeRate;
};

export const calcInUSD = (amount: number, closePrice: number) => {
    return amount / closePrice;
};

export const convertUSDtoTON = (inUSD: number, tonExchangeRate: number) => {
    return inUSD / tonExchangeRate;
};
export const convertTONtoAmount = (inTON: number, tonExchangeRate: number, closePrice: number) => {
    return inTON * tonExchangeRate * closePrice;
};
