import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Zoom } from 'swiper/modules';

import { AnimatePresence, motion } from 'framer-motion';

import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import './styles.css';

import { Box, Image } from '@chakra-ui/react';
import { IImage } from '../../interfaces';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const imageVariants = {
    hidden: {
        opacity: 0,
        x: 60,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            delay: 0.08,
        },
    },
};

interface Props {
    images: IImage[];
}

export default function Carousel({ images }: Props) {
    return (
        <Box height="350px" width="100%">
            <Swiper
                pagination={{
                    dynamicBullets: true,
                }}
                zoom
                modules={[Pagination, Zoom]}
                className="mySwiper">
                <AnimatePresence>
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <motion.div
                                key={index}
                                variants={imageVariants}
                                custom={index}
                                initial="hidden"
                                animate="visible"
                                style={{ height: '320px' }}>
                                <Box className="swiper-zoom-container">
                                    <LazyLoadImage
                                        effect="blur"
                                        src={image.name}
                                        style={{ maxHeight: '320px' }}
                                    />
                                </Box>
                            </motion.div>
                        </SwiperSlide>
                    ))}
                </AnimatePresence>
            </Swiper>
        </Box>
    );
}
