import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { useEditUserMutation } from '../../redux/platimarket/platimarket.api';
import SuccessDialog from './components/SuccessDialog';
import EmailDialog from './components/EmailDialog';

interface Props {
    isOpen: boolean;
    setIsOpenModal: (isOpen: boolean) => void;
    redirectURL: string;
}

export default function EmailModal({ isOpen, setIsOpenModal, redirectURL }: Props) {
    const initialRef = React.useRef(null);

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [
        editUser,
        { isLoading: isLoadingEditUser, isSuccess: isSuccessEditUser, data: editedUser },
    ] = useEditUserMutation();

    const closeModal = () => {
        setIsOpenModal(false);
    };

    const addEmail = () => {
        const isValidEmail = EmailValidator.validate(email);

        if (!(isValidEmail || email.length)) {
            setErrorMessage('Введите корректный адрес почты');
        } else {
            setErrorMessage(null);
            editUser({ email });
        }
    };

    useEffect(() => {
        if (isSuccessEditUser) {
            window.open(redirectURL + '&email=' + email, '_self');
        }
    }, [isSuccessEditUser]);

    return (
        <>
            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent m="8">
                    <ModalHeader fontWeight="500">Добавление почты</ModalHeader>
                    <ModalCloseButton />
                    {isSuccessEditUser ? (
                        <SuccessDialog />
                    ) : (
                        <EmailDialog
                            email={email}
                            setEmail={setEmail}
                            addEmail={addEmail}
                            isLoadingEditUser={isLoadingEditUser}
                            errorMessage={errorMessage}
                        />
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
