import { Text, List, ListItem, Button, Card } from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';
import Colors from '../../config/colors';
import { Link } from 'react-router-dom';
import truncate from '../../utils/truncate';
import { IOrder } from '../../interfaces';
import { ORDER_STATUSES } from '../../config/orders';
import OrderStatus from '../orderStatus/OrderStatus';
import JumpButton from '../jumpButton/JumpButton';

interface Props {
    order: IOrder;
}

export default function OrderItem({ order }: Props) {
    const orderStatuses = Object.values(ORDER_STATUSES);
    const orderStatus = orderStatuses.find((status) => status.id === order.orderStatus.id);
    const purchaseURL = 'https://digiseller.market/info/buy.asp?id_i=' + order.invoiceId;

    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="unstyled"
            padding="15px"
            boxShadow="rgba(149, 157, 165, 0.25) 0px 3px 12px"
            borderRadius="10px"
            fontWeight="500"
            fontSize="14px">
            <List spacing={3} width="100%">
                <ListItem display="flex" justifyContent="space-between">
                    <OrderStatus orderStatus={orderStatus} />
                </ListItem>
                <ListItem>
                    <Text color={Colors.darkGrey} fontSize="12px">
                        Название товара
                    </Text>
                    <Text>{truncate(order.product.name)}</Text>
                </ListItem>
                <ListItem>
                    <Text color={Colors.darkGrey} fontSize="12px">
                        Стоимость
                    </Text>
                    <Text>{order.product.price.toLocaleString()} RUB</Text>
                </ListItem>
                <ListItem>
                    <Link to={purchaseURL} state={{ pageName: order.product.subcategory.name }}>
                        <JumpButton name="Получить товар" />
                    </Link>
                </ListItem>
            </List>
        </Card>
    );
}
