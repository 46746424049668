import { CurrencyInput } from '../currencyInput/CurrencyInput';
import { InputGroup } from '@chakra-ui/react';
import { useState } from 'react';
import Colors from '../../../../../config/colors';

interface Props {
    onChangeAmount: (value: number) => void;
    currency: string;
    amount: number;
}
export default function AmountInput({ onChangeAmount, currency, amount }: Props) {
    const [value, setValue] = useState<number | string>(amount);

    const onChangeValue = (value: string) => {
        if (value?.includes(',')) {
            setValue(value.replace(',', '.'));
        }
        if (Number(value) === +value || value.includes('.') || !value.length) {
            const amount = +value;

            onChangeAmount(amount);
            setValue(value);
        }
    };

    return (
        <InputGroup width="100%" borderRadius="10px" background={Colors.lightGrey}>
            <CurrencyInput
                value={value}
                suffix={currency}
                onChange={(e) => onChangeValue(e.target.value)}
            />
        </InputGroup>
    );
}
