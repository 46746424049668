import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useState } from 'react';
import Colors from '../../../../../config/colors';

interface Props {
    saveLogin: (isSave: boolean) => void;
    isSave: boolean;
}

export default function SaveLoginSwitch({ saveLogin, isSave }: Props) {
    return (
        <FormControl display="flex" alignItems="center" gap="3">
            <Switch
                isChecked={isSave}
                id="save"
                size="lg"
                sx={{
                    '.chakra-switch__track[data-checked]:not([data-theme])': {
                        backgroundColor: Colors.blue,
                    },
                }}
                onChange={() => saveLogin(!isSave)}
            />
            <FormLabel htmlFor="save" mb="0">
                Сохранить логин
            </FormLabel>
        </FormControl>
    );
}
