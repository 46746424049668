import {
    Box,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Stack,
} from '@chakra-ui/react';
import { useActions } from '../../hooks/actions';
import { useAppSelector } from '../../hooks/redux';
import Colors from '../../config/colors';
import { ArrowBackIcon, HamburgerIcon } from '@chakra-ui/icons';
import About from '../about/About';
import ProfileButton from '../profileButton/ProfileButton';
import { Link, useLocation } from 'react-router-dom';
import { useTelegram } from '../../hooks/useTelegram';
import { TbMessageCirclePlus } from 'react-icons/tb';

export default function Profile() {
    const { user, onClose } = useTelegram();
    const location = useLocation();
    const isOpen = useAppSelector((state) => state.navigation.isOpen);

    const { setOpen } = useActions();

    const setPathHandler = (path: string) => {
        setOpen(false);
    };

    const setOpenHandler = (isOpen: boolean) => {
        setOpen(isOpen);
    };

    return (
        <>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={() => setOpenHandler(false)}
                autoFocus={false}>
                <DrawerOverlay />
                <DrawerContent maxW="200px">
                    <DrawerHeader
                        borderBottomWidth="1px"
                        fontSize="14px"
                        fontWeight="500"
                        textAlign="center">
                        Профиль
                    </DrawerHeader>
                    <DrawerBody>
                        <Stack>
                            <About
                                userId={user?.id}
                                name={user?.first_name}
                                login={user?.username}
                            />

                            <Box display="flex" flexDirection="column" gap="9px" mt="9px">
                                <Link
                                    to="/orders"
                                    state={{ previousPath: location.pathname }}
                                    onClick={() => setPathHandler('/orders')}>
                                    <ProfileButton
                                        borderColor={Colors.lightOrange}
                                        color={Colors.orange}
                                        text={'Мои заказы'}
                                        createIcon={() => <HamburgerIcon boxSize={4} />}
                                    />
                                </Link>
                                <Link
                                    to="/support"
                                    state={{ previousPath: location.pathname }}
                                    onClick={() => setPathHandler('/support')}>
                                    <ProfileButton
                                        text={'Поддержка'}
                                        createIcon={() => <TbMessageCirclePlus size="18px" />}
                                    />
                                </Link>
                                <Link to="/catalog" onClick={() => setPathHandler('/catalog')}>
                                    <ProfileButton
                                        text={'В каталог'}
                                        createIcon={() => <ArrowBackIcon boxSize={4} />}
                                    />
                                </Link>
                            </Box>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}
