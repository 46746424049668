import { Box, Button, Container, Flex, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import AmountInput from './compontents/amountInput/AmountInput';
import Colors from '../../../config/colors';
import { Link } from 'react-router-dom';

import DollarIcon from '../../../config/icons/dollar.svg';
import DiamondTonIcon from '../../../config/icons/diamond-ton.svg';
import { useAppSelector } from '../../../hooks/redux';
import { UpDownIcon } from '@chakra-ui/icons';
import { useActions } from '../../../hooks/actions';
import { useGetCurrenciesQuery, useGetRateQuery } from '../../../redux/platimarket/platimarket.api';
import {
    calcComission,
    calcInUSD,
    calcMinAmount,
    calcInTON,
    convertUSDtoTON,
    convertTONtoAmount,
} from '../../../utils/replenishment';
import currenciesList from '../../../config/currenciesList';

export default function TonkeeperAmountPage() {
    const inTON = useAppSelector((state) => state.replenishment.steam.inTON);
    const amount = useAppSelector((state) => state.replenishment.steam.amount);
    const isSwap = useAppSelector((state) => state.replenishment.steam.isSwap);
    const currencyValue = useAppSelector((state) => state.replenishment.steam.currency);

    const setInTON = (value: number) => {
        setFieldSteamReplenishment({ field: 'inTON', value });
    };

    const setAmount = (value: number) => {
        setFieldSteamReplenishment({ field: 'amount', value });
    };

    const setSwap = (value: boolean) => {
        setFieldSteamReplenishment({ field: 'isSwap', value });
    };

    const { data: currenciesData } = useGetCurrenciesQuery();
    const { data: rateData } = useGetRateQuery();

    const currencies = currenciesData?.data ?? [];

    const currency = currencies.find((item) =>
        item.currency_pair.split(':')[1].includes(currencyValue),
    );

    const currentCurrencyListItem = currenciesList.find((item) => item.name === currencyValue);

    const closePrice = currency?.close_price ?? 1;

    const tonExchangeRate = rateData?.tonUsd!;
    const inUSD = Number(calcInUSD(amount, closePrice).toFixed(2));

    const minAmount = currentCurrencyListItem?.minAmount!;
    const maxAmount = currentCurrencyListItem?.maxAmount!;

    const minTON = calcInTON(closePrice, minAmount, tonExchangeRate);
    const maxTON = calcInTON(closePrice, maxAmount, tonExchangeRate);

    const { setFieldSteamReplenishment } = useActions();

    const [errorMessage, setErrorMessage] = useState<string>('');

    const isContinue = !errorMessage.length && amount;

    const onChangeAmount = (value: number) => {
        if (isSwap && value < minTON) {
            setErrorMessage('Минимальная сумма: ' + minTON);
        } else if (!isSwap && value < minAmount) {
            setErrorMessage('Минимальная сумма: ' + minAmount);
        } else if (isSwap && value > maxTON) {
            setErrorMessage('Максимальная сумма: ' + maxTON);
        } else if (!isSwap && value > maxAmount) {
            setErrorMessage('Максимальная сумма: ' + maxAmount);
        } else {
            setErrorMessage('');
            if (isSwap) {
                setInTON(value);
            } else {
                setAmount(value);
            }
        }
    };

    const onSwap = () => {
        setSwap(!isSwap);
    };

    useEffect(() => {
        if (rateData) {
            const value = convertUSDtoTON(inUSD, tonExchangeRate).toFixed(2);

            if (!isSwap) {
                setInTON(Number(value));
            }
        }
    }, [amount, rateData]);

    useEffect(() => {
        if (isSwap) {
            const value = +convertTONtoAmount(+inTON, tonExchangeRate, closePrice).toFixed(2);

            setAmount(value);
        }
    }, [inTON]);

    useEffect(() => {
        setSwap(false);
    }, []);

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="4">
                <Box>
                    <Text fontSize="20px">Сумма пополнения </Text>
                    <Box display="flex" alignItems="center" gap="3px" as={Link} to={'/currency'}>
                        <Text fontWeight="300">
                            Введите сумму пополнения в{' '}
                            <span style={{ color: Colors.blue, fontWeight: '600' }}>
                                {currencyValue}
                            </span>
                        </Text>
                        <UpDownIcon fontSize="14px" color={Colors.blue} />
                    </Box>
                </Box>
                <AmountInput
                    onChangeAmount={onChangeAmount}
                    amount={amount}
                    currency={currencyValue}
                    onSwap={onSwap}
                    isSwap={isSwap}
                    inTON={inTON}
                    setInTON={setInTON}
                />

                {!errorMessage.length && (
                    <Flex gap="3" width="100%">
                        {currencyValue !== 'USD' && (
                            <Flex gap="3" alignItems="center">
                                <Text>
                                    В USD: <span style={{ fontWeight: 500 }}>{inUSD}</span>
                                </Text>
                                <Box
                                    background="rgba(65, 138, 81, 0.1)"
                                    padding="3px"
                                    borderRadius="6px">
                                    <Image
                                        src={DollarIcon}
                                        alt="convert-icon"
                                        height="100%"
                                        width="18px"
                                    />
                                </Box>
                            </Flex>
                        )}
                        {currencyValue !== 'USD' && <Box borderWidth="1px"></Box>}
                        <Flex gap="3" alignItems="center">
                            <Text>
                                В {isSwap ? currencyValue : 'TON'}:{' '}
                                <span style={{ fontWeight: 500 }}>{isSwap ? amount : inTON}</span>
                            </Text>

                            <Box
                                background="rgba(55, 159, 239, 0.1)"
                                padding="3px"
                                borderRadius="6px">
                                <Image
                                    src={isSwap ? currentCurrencyListItem?.icon : DiamondTonIcon}
                                    alt="convert-icon"
                                    height="100%"
                                    width="18px"
                                />
                            </Box>
                        </Flex>
                    </Flex>
                )}
                <Box display="flex" flexDirection="column" gap="2">
                    {errorMessage && <Text color={Colors.red}>{errorMessage}</Text>}
                    <Text fontWeight="300">
                        Зачисление на Steam производится в USD (долларами США) и в последствии
                        конвертируется в валюту аккаунта.
                    </Text>
                </Box>
                <Box
                    position="fixed"
                    display="flex"
                    alignItems="center"
                    bottom="0"
                    zIndex="3"
                    left="0"
                    right="0">
                    <Button
                        as={Link}
                        to={'/tonkeeper/steam/confirm'}
                        isDisabled={!isContinue}
                        pointerEvents={isContinue ? 'auto' : 'none'}
                        variant="solid"
                        color="white"
                        background={Colors.blue}
                        fontSize="16px"
                        fontWeight="500"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="12px"
                        width="100%"
                        padding="30px 0px"
                        borderRadius="0px">
                        <Text>Продолжить</Text>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
