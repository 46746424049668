import { jwtDecode } from 'jwt-decode';

export default (token: string) => {
    const decodedToken = jwtDecode(token);
    const currentDate = new Date();

    if (decodedToken?.exp && decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
    }
    return true;
};
