import { configureStore } from '@reduxjs/toolkit';

import navigation from './platimarket/slices/navigationSlice';
import auth from './platimarket/slices/authSlice';
import search from './platimarket/slices/searchSlice';

import { platimarketApi } from './platimarket/platimarket.api';
import { setupListeners } from '@reduxjs/toolkit/query';

import modalReducer from './platimarket/slices/modals/modalReducer';
import replenishmentReducer from './platimarket/slices/replenishmentSlice'

export const store = configureStore({
    reducer: {
        [platimarketApi.reducerPath]: platimarketApi.reducer,
        navigation,
        auth,
        search,
        modal: modalReducer,
        replenishment: replenishmentReducer
    },
    middleware: (buildGetDefaultMiddleware) =>
        buildGetDefaultMiddleware().concat(platimarketApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
