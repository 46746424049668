import { Card, CardBody, Heading, Image, Stack } from '@chakra-ui/react';
import { ICategory } from '../../interfaces';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CATEGORY_IMAGES_PATH } from '../../config/images';

interface Props {
    category: ICategory;
}

export default function Category({ category }: Props) {
    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="unstyled"
            padding="12px"
            boxShadow="rgba(149, 157, 165, 0.25) 0px 3px 12px"
            borderRadius="10px">
            <LazyLoadImage
                src={CATEGORY_IMAGES_PATH + category.image}
                effect="blur"
                height="100%"
                style={{
                    objectFit: 'cover',
                    width: '100%',
                    position: 'relative',
                    zIndex: 1,
                    borderRadius: '10px',
                }}
            />

            <Stack position="absolute" zIndex="1" height="100%" top="0px">
                <CardBody display="flex" alignItems="center">
                    <Heading size="lm" color="white" fontWeight="500" ml="20px">
                        {category.name}
                    </Heading>
                </CardBody>
            </Stack>
        </Card>
    );
}
