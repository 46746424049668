import { Box, Button, Container, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import CurrencyList from './components/currencyList/CurrencyList';
import { Link, useNavigate } from 'react-router-dom';
import { useActions } from '../../../hooks/actions';
import { useAppSelector } from '../../../hooks/redux';
import Colors from '../../../config/colors';

export default function CurrencyPage() {
    const navigate = useNavigate();

    const { setFieldSteamReplenishment } = useActions();
    const currencyValue = useAppSelector((state) => state.replenishment.steam.currency);
    const [currency, setCurrency] = useState(currencyValue);

    const saveCurrency = () => {
        setFieldSteamReplenishment({ field: 'currency', value: currency });
        navigate(-1);
    };

    const setAmount = (value: number) => {
        setFieldSteamReplenishment({ field: 'amount', value });
    };

    useEffect(() => {
        setAmount(0);
    }, []);

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="3">
                <Box>
                    <Text fontSize="20px">Steam валюта</Text>
                    <Text fontWeight="300">Выберите валюту вашего Steam аккаунта</Text>
                </Box>
                <Box>
                    <CurrencyList selectCurrency={setCurrency} currency={currency} />
                </Box>
                <Box
                    position="fixed"
                    display="flex"
                    alignItems="center"
                    bottom="0"
                    zIndex="3"
                    left="0"
                    right="0">
                    <Button
                        onClick={saveCurrency}
                        variant="solid"
                        color="white"
                        background={Colors.blue}
                        fontSize="16px"
                        fontWeight="500"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="12px"
                        width="100%"
                        padding="30px 0px"
                        borderRadius="0px">
                        <Text>Сохранить</Text>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
