import { Box, Container } from '@chakra-ui/react';

import { useState } from 'react';
import OrderList from '../../orderList/OrderList';
import { useLocation } from 'react-router-dom';
import findRoute from '../../../utils/findRoute';
import EmptyAlert from '../../emptyAlert/EmptyAlert';
import { useGetOrdersQuery } from '../../../redux/platimarket/platimarket.api';
import Loader from '../../loader/Loader';

export default function Orders() {
    const { isLoading, isError, data } = useGetOrdersQuery();

    const location = useLocation();
    const pathName = location.pathname;

    const findedRoute = findRoute(pathName);
    const emptyMessage = findedRoute?.emptyMessage;

    return (
        <Container paddingBottom="40px">
            {isLoading ? (
                <Box display="flex" justifyContent="center" mt="30px">
                    <Loader />
                </Box>
            ) : data?.length ? (
                <OrderList orders={data} />
            ) : (
                <EmptyAlert message={emptyMessage} />
            )}
        </Container>
    );
}
