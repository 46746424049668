import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { navigationActions } from '../redux/platimarket/slices/navigationSlice';
import { authActions } from '../redux/platimarket/slices/authSlice';
import { searchActions } from '../redux/platimarket/slices/searchSlice';
import modalActions from '../redux/platimarket/slices/modals/actions';
import { replenishmentActions } from '../redux/platimarket/slices/replenishmentSlice';

const actions = {
    ...navigationActions,
    ...authActions,
    ...searchActions,
    ...modalActions,
    ...replenishmentActions
};

export const useActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators(actions, dispatch);
};
