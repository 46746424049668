import { Box } from '@chakra-ui/react';

import { ICategory } from '../../interfaces';
import Category from '../category/Category';
import { Link } from 'react-router-dom';
import { cardLazyVariants } from '../../config/animation';

import { motion, AnimatePresence } from 'framer-motion';

interface Props {
    categories: ICategory[];
}

export default function Categories({ categories }: Props) {
    return (
        <Box display="flex" flexDirection="column" gap="3">
            <AnimatePresence>
                {categories.map((category, index) => (
                    <motion.div
                        key={index}
                        variants={cardLazyVariants}
                        custom={index}
                        initial="hidden"
                        animate="visible">
                        <Link
                            to={category.id === 4 ? '/replenishment' : '/category/' + category.id}
                            state={{ pageName: category.name }}
                            key={index}>
                            <Category category={category} />
                        </Link>
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
}
