import DollarIcon from './icons/dollar.svg';
import RubleIcon from './icons/ruble.svg';
import TengeIcon from './icons/tenge.svg';
import HryvniaIcon from './icons/hryvnia.svg';

interface ICurrency {
    name: string;
    icon: any;
    minAmount: number;
    maxAmount: number;
}

const currenciesList: ICurrency[] = [
    {
        name: 'RUB',
        icon: RubleIcon,
        minAmount: 100,
        maxAmount: 8000,
    },
    {
        name: 'KZT',
        icon: TengeIcon,
        minAmount: 500,
        maxAmount: 40000,
    },
    {
        name: 'USD',
        icon: DollarIcon,
        minAmount: 1.2,
        maxAmount: 90,
    },
    {
        name: 'UAH',
        icon: HryvniaIcon,
        minAmount: 50,
        maxAmount: 3600,
    },
];

export default currenciesList;
