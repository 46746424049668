import { Box, Button, Progress, Text } from '@chakra-ui/react';
import SuccessAnimation from '../../successAnimation/SuccessAnimation';
import Colors from '../../../config/colors';

interface Props {
    closeModal: () => void;
}

export default function SuccessDialog({ closeModal }: Props) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="60px"
            padding="30px 0px 50px 0px">
            <Box display="flex" flexDirection="column" gap="25px">
                <SuccessAnimation />
                <Text color={Colors.darkGrey} fontSize="18px">
                    Успешно отправлено
                </Text>
            </Box>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" gap="20px">
                <Text color={Colors.darkGrey}>Ожидайте сообщение от поддержки</Text>
                <Button fontWeight="500" color={Colors.blue} onClick={closeModal}>
                    Вернуться назад
                </Button>
            </Box>
        </Box>
    );
}
