import { Box, Container } from '@chakra-ui/react';

import Categories from '../../categories/Categories';

import { useLocation } from 'react-router-dom';
import findRoute from '../../../utils/findRoute';
import EmptyAlert from '../../emptyAlert/EmptyAlert';
import { useEffect } from 'react';
import {
    useGetCategoriesQuery,
    useLazyGetProductsQuery,
} from '../../../redux/platimarket/platimarket.api';
import Loader from '../../loader/Loader';
import { useDebounce } from '../../../hooks/useDebounce';
import { useAppSelector } from '../../../hooks/redux';
import ProductList from '../../productList/ProductList';
import SupportAlert from '../../supportAlert/SupportAlert';

export default function Catalog() {
    const location = useLocation();
    const { isLoading, isError, data } = useGetCategoriesQuery({
        orderBy: 'position',
        sortBy: 'ASC',
    });
    const searchValue = useAppSelector((state) => state.search.name);
    const debouncedSearchValue = useDebounce(searchValue);

    const pathName = location.pathname;
    const findedRoute = findRoute(pathName);
    const emptyMessage = findedRoute?.emptyMessage;

    const [getProducts, { isLoading: isLoadingProducts, data: products }] =
        useLazyGetProductsQuery();

    useEffect(() => {
        if (debouncedSearchValue.length) {
            getProducts({
                name: debouncedSearchValue,
                isMain: true,
            });
        }
    }, [debouncedSearchValue]);

    return (
        <Container height="calc(100vh - 70px)">
            {isLoading || isLoadingProducts ? (
                <Box display="flex" justifyContent="center" mt="30px">
                    <Loader />
                </Box>
            ) : data?.length ? (
                debouncedSearchValue.length ? (
                    products?.length ? (
                        <ProductList products={products} />
                    ) : (
                        <SupportAlert
                            message={`По запросу «${debouncedSearchValue}» ничего не найдено`}
                            isSearchButton={false}
                        />
                    )
                ) : (
                    <Categories categories={data} />
                )
            ) : (
                <EmptyAlert message={emptyMessage} />
            )}
        </Container>
    );
}
