import { Button, Text } from '@chakra-ui/react';

interface Props {
    createIcon: () => React.ReactElement;
    text: string;
    borderColor?: string;
    color?: string;
}
export default function ProfileButton({ createIcon, text, ...props }: Props) {
    return (
        <Button
            variant="outline"
            fontSize="15px"
            fontWeight="500"
            display="flex"
            justifyContent="space-between"
            gap="7px"
            width="100%"
            {...props}>
            {createIcon()}
            <Text>{text}</Text>
        </Button>
    );
}
