import { Box } from '@chakra-ui/react';

import { IOrder } from '../../interfaces';
import OrderItem from '../orderItem/orderItem';
import { AnimatePresence, motion } from 'framer-motion';
import { cardBasicVariants, cardLazyVariants } from '../../config/animation';

interface Props {
    orders: IOrder[];
}

export default function OrderList({ orders }: Props) {
    return (
        <Box display="flex" flexDirection="column" gap="3">
            <AnimatePresence>
                {orders.map((order: IOrder, index) => (
                    <motion.div
                        key={index}
                        variants={cardLazyVariants}
                        custom={index}
                        initial="hidden"
                        animate="visible">
                        <OrderItem order={order} key={order.id} />
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
}
