import { Box, SimpleGrid } from '@chakra-ui/react';

import { ISubcategory } from '../../interfaces';
import SubcategoryCard from '../subcategoryCard/SubcategoryCard';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { cardBasicVariants } from '../../config/animation';

interface Props {
    subcategories: ISubcategory[];
}

export default function SubcategoryList({ subcategories }: Props) {
    return (
        <Box display="flex" flexDirection="column" gap="3" fontWeight="500">
            <AnimatePresence>
                {subcategories.map((subcategory, index) => (
                    <motion.div
                        key={index}
                        variants={cardBasicVariants}
                        custom={index}
                        initial="hidden"
                        animate="visible">
                        <Link
                            to={'/product/' + subcategory.id}
                            state={{ pageName: subcategory.name }}>
                            <SubcategoryCard subcategory={subcategory} />
                        </Link>
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
}
