import NavbarList from '../navbarList/NavbarList';
import routes from '../../config/routes';
import { useLocation } from 'react-router-dom';

export default function Navbar() {
    const location = useLocation();
    const pathName = location.pathname;

    const findedRoute = routes.find((route) =>
        route.path.startsWith('/' + pathName.split('/')[1]),
    );

    return findedRoute?.showNavbar ? <NavbarList routes={routes} /> : <></>;
}
