import { Box, Divider } from '@chakra-ui/react';

import './styles/main.css';
import Colors from '../../../../../config/colors';

const currencyList = [
    { label: 'RUB', value: 'RUB', description: 'Russian ruble' },
    { label: 'USD', value: 'USD', description: 'US dollar' },
    { label: 'KZT', value: 'KZT', description: 'Kazakhstani tenge' },
    { label: 'UAH', value: 'UAH', description: 'Ukrainian hryvnia' },
];

interface Props {
    selectCurrency: (value: string) => void;
    currency: string;
}

export default function CurrencyList({ selectCurrency, currency }: Props) {
    return (
        <Box backgroundColor={Colors.lightGrey} padding="15px 0px 15px 15px" borderRadius="10px">
            {currencyList.map((item, index) => (
                <label className="radio-container" onClick={() => selectCurrency(item.value)}>
                    <p>
                        {item.label} <span style={{ fontWeight: '500' }}>({item.description})</span>
                    </p>
                    {index < currencyList.length - 1 && <Divider />}

                    <input type="radio" name="radio" checked={item.value === currency} />
                    <span className="radio-checkmark"></span>
                </label>
            ))}
        </Box>
    );
}
