import { Box, Button, Container, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import AmountInput from './compontents/amountInput/AmountInput';
import Colors from '../../../config/colors';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import currenciesList from '../../../config/currenciesList';

export default function AaioAmountPage() {
    const amount = useAppSelector((state) => state.replenishment.steam.amount);
    const currencyValue = useAppSelector((state) => state.replenishment.steam.currency);

    const currentCurrencyListItem = currenciesList.find((item) => item.name === currencyValue);

    const minAmount = 450;
    const maxAmount = currentCurrencyListItem?.maxAmount!;

    const { setFieldSteamReplenishment } = useActions();

    const setAmount = (value: number) => {
        setFieldSteamReplenishment({ field: 'amount', value });
    };

    const [errorMessage, setErrorMessage] = useState('');

    const isContinue = !errorMessage.length && amount;

    const onChangeAmount = (value: number) => {
        if (value < minAmount) {
            setErrorMessage('Минимальная сумма: ' + minAmount);
        } else if (value > maxAmount) {
            setErrorMessage('Максимальная сумма: ' + maxAmount);
        } else {
            setErrorMessage('');
            setAmount(value);
        }
    };

    useEffect(() => {
        setAmount(0);
    }, []);

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="4">
                <Box>
                    <Text fontSize="20px">Сумма пополнения </Text>
                    <Box display="flex" alignItems="center" gap="3px">
                        <Text fontWeight="300">
                            Введите сумму пополнения в{' '}
                            <span style={{ color: Colors.blue, fontWeight: '600' }}>
                                RUB
                            </span>
                        </Text>
                    </Box>
                </Box>
                <AmountInput
                    onChangeAmount={onChangeAmount}
                    amount={amount}
                    currency={currencyValue}
                />

                <Box display="flex" flexDirection="column" gap="2">
                    {!!errorMessage.length && <Text color={Colors.red}>{errorMessage}</Text>}
                    <Text fontWeight="300">
                        Зачисление на Steam производится в USD (долларами США) и в последствии
                        конвертируется в валюту аккаунта.
                    </Text>
                </Box>
                <Box
                    position="fixed"
                    display="flex"
                    alignItems="center"
                    bottom="0"
                    zIndex="3"
                    left="0"
                    right="0">
                    <Button
                        as={Link}
                        to={'/aaio/steam/confirm'}
                        isDisabled={!isContinue}
                        pointerEvents={isContinue ? 'auto' : 'none'}
                        variant="solid"
                        color="white"
                        background={Colors.blue}
                        fontSize="16px"
                        fontWeight="500"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="12px"
                        width="100%"
                        padding="30px 0px"
                        borderRadius="0px">
                        <Text>Продолжить</Text>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
