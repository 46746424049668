import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useToast,
} from '@chakra-ui/react';

import { useCallback, useEffect, useRef, useState } from 'react';

import ResizeTextarea from 'react-textarea-autosize';
import { IoMdSend } from 'react-icons/io';
import { CheckIcon, TimeIcon } from '@chakra-ui/icons';
import { IGetProductParams } from '../../../../../redux/platimarket/interfaces/query-params';
import { useAppSelector } from '../../../../../hooks/redux';
import { useActions } from '../../../../../hooks/actions';
import { useLazyGetUserPhotoQuery } from '../../../../../redux/platimarket/platimarket.api';
import SupportMessage from '../supportMessage/SupportMessage';
import Colors from '../../../../../config/colors';

export default function ModalShow() {
    const initialRef = useRef(null);
    const finalRef = useRef(null);

    // Scroll to last message
    const scrollRef = useCallback((node: any) => {
        if (node) {
            node.lastElementChild?.scrollIntoView();
        }
    }, []);

    const supportMessage = useAppSelector(
        (state) => state.modal.supportMessage.showModal.supportMessage,
    );
    const { setSupportMessageShowModal } = useActions();

    const isOpen = !!supportMessage;
    const createdAt = new Date(supportMessage?.createdAt ?? '');
    const receivedAt = new Date(supportMessage?.receivedAt ?? '');

    const [
        getUserPhoto,
        { isLoading: isLoadingUserPhoto, isError: isErrorUserPhoto, data: userPhoto },
    ] = useLazyGetUserPhotoQuery();

    const closeModal = () => {
        setSupportMessageShowModal({ supportMessage: null });
    };

    useEffect(() => {
        if (supportMessage?.userId) {
            getUserPhoto({ userId: supportMessage.userId! });
        }
    }, [supportMessage?.userId]);

    return (
        <>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen!}
                onClose={() => closeModal()}>
                <ModalOverlay />
                <ModalContent m="8">
                    <ModalHeader fontWeight="500">Сообщение</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Box
                            overflow="auto"
                            display="flex"
                            flexDirection="column"
                            gap="5"
                            maxHeight="500px"
                            ref={scrollRef}>
                            <SupportMessage
                                sentAt={createdAt}
                                supportMessage={supportMessage}
                                userPhotoURL={userPhoto?.url ?? null}
                            />
                            {supportMessage?.replyMessage && supportMessage?.receivedAt && (
                                <SupportMessage
                                    sentAt={receivedAt}
                                    supportMessage={supportMessage}
                                    isReply={true}
                                />
                            )}
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        {supportMessage?.replyMessage && supportMessage?.receivedAt ? (
                            <Box
                                width="100%"
                                backgroundColor="#D2FAEB"
                                borderRadius="8px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap="2"
                                padding="12px"
                                color={Colors.darkGreen}>
                                <Text>Вам ответили на сообщение</Text>
                                <CheckIcon />
                            </Box>
                        ) : (
                            <Box
                                width="100%"
                                backgroundColor="gray.100"
                                borderRadius="8px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                gap="2"
                                padding="12px">
                                <Text>Отвечаем в течении 24 часов</Text>
                                <TimeIcon />
                            </Box>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
