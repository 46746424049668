export function useTelegram() {
    const tempWindow: any = window;
    const tg = tempWindow.Telegram.WebApp;
    tg.enableClosingConfirmation();
    tg.expand();

    const onClose = () => {
        tg.close();
    };

    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    };

    return {
        onToggleButton,
        onClose,
        tg,
        initData: tg.initData,
        user: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,
    };
}
