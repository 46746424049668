import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription, AlertTitle, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import JumpButton from '../jumpButton/JumpButton';

interface Props {
    message?: string;
    isSearchButton?: boolean;
}

export default function EmptyAlert({
    message = 'Товары не найдены',
    isSearchButton = true,
}: Props) {
    return (
        <Alert
            variant="style"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="100vh"
            gap="4"
            paddingBottom="230px">
            <Box>
                <QuestionOutlineIcon boxSize="25px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    {message}
                </AlertTitle>
            </Box>
            <AlertDescription maxWidth="sm">
                {isSearchButton && (
                    <Link to="/catalog">
                        <JumpButton name="В каталог" padding="24px 70px" />
                    </Link>
                )}
            </AlertDescription>
        </Alert>
    );
}
