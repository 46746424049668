import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';

import TonkeeperLoginPage from './TonkeeperLoginPage';
import TonkeeperAuthPage from './TonkeeperAuthPage';

export default function TonkeeperPage() {
    const wallet = useTonWallet();

    const [tonConnectUI, setOptions] = useTonConnectUI();

    tonConnectUI.onStatusChange((wallet) => {
        if (wallet) {
            return <TonkeeperLoginPage />;
        }
    });

    return !wallet ? <TonkeeperAuthPage /> : <TonkeeperLoginPage />;
}
