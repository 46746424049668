import { Box, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import findRoute from '../../utils/findRoute';

interface Props {
    path: string;
}

export default function Breadcrumb({ path }: Props) {
    const navigate = useNavigate();
    const location = useLocation();

    const findedRoute = findRoute(path);
    const pageName = location.state?.pageName ?? findedRoute?.name;

    const goToPreviousPage = () => {
        navigate(-1);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            position="relative">
            <Box onClick={() => goToPreviousPage()}>
                <ChevronLeftIcon position="absolute" left="0" top="0" boxSize={6} />
            </Box>
            <Text>{pageName}</Text>
        </Box>
    );
}
