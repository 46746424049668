import { Text, List, ListItem, Button, Card } from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';
import Colors from '../../../../config/colors';
import { Link } from 'react-router-dom';
import truncate from '../../../../utils/truncate';
import { ISupportMessage } from '../../../../interfaces';
// import { ORDER_STATUSES } from '../../config/orders';
// import OrderStatus from '../orderStatus/OrderStatus';
import JumpButton from '../../../jumpButton/JumpButton';
import { useActions } from '../../../../hooks/actions';
import SupportStatus from './supportStatus/SupportStatus';

interface Props {
    supportMessage: ISupportMessage;
}

export default function SupportMessageItem({ supportMessage }: Props) {
    const { setSupportMessageShowModal } = useActions();

    const showMessage = () => {
        setSupportMessageShowModal({ supportMessage });
    };
    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="unstyled"
            padding="15px"
            boxShadow="rgba(149, 157, 165, 0.25) 0px 3px 12px"
            borderRadius="10px"
            fontWeight="500"
            fontSize="14px">
            <List spacing={3} width="100%">
                <ListItem display="flex" justifyContent="space-between">
                    <SupportStatus supportStatus={supportMessage.supportStatus} />
                </ListItem>
                <ListItem>
                    <Text color={Colors.darkGrey} fontSize="12px">
                        Сообщение
                    </Text>
                    <Text>{truncate(supportMessage.message)}</Text>
                </ListItem>
                {supportMessage.replyMessage && (
                    <ListItem>
                        <Text color={Colors.darkGrey} fontSize="12px">
                            Ответ
                        </Text>
                        <Text>{supportMessage?.replyMessage}</Text>
                    </ListItem>
                )}
                <ListItem>
                    <Button
                        variant="solid"
                        border="none"
                        color={Colors.blue}
                        fontSize="14px"
                        fontWeight="500"
                        display="flex"
                        alignItems="center"
                        gap="12px"
                        width="100%"
                        onClick={showMessage}>
                        <Text>Посмотреть сообщение</Text>
                    </Button>
                </ListItem>
            </List>
        </Card>
    );
}
