import { Box, Tag, TagLabel } from '@chakra-ui/react';
import { IOrderStatusContent } from '../../interfaces/index';

interface Props {
    orderStatus: IOrderStatusContent;
    hideName?: boolean;
}

export default function OrderStatus({ orderStatus, hideName = false }: Props) {
    return (
        <Tag
            size="sm"
            minWidth={hideName ? 'fit-content' : '144px'}
            variant="unstyled"
            color={orderStatus.color ?? 'gray'}
            borderColor={orderStatus.borderColor ?? 'gray'}
            borderWidth="1px"
            display="flex"
            alignItems="center"
            gap="5px"
            padding="7px 12px">
            {orderStatus.createIcon && orderStatus.createIcon()}
            <Box width="100%" display="flex" justifyContent="center">
                {!hideName && <TagLabel>{orderStatus.name}</TagLabel>}
            </Box>
        </Tag>
    );
}
