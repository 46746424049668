import { IOrderStatus } from '../interfaces';
import { CheckIcon, NotAllowedIcon, TimeIcon } from '@chakra-ui/icons';
import Colors from './colors';

export const ORDER_STATUSES: IOrderStatus = {
    awaiting: {
        id: 1,
        name: 'Ожидает оплаты',
        createIcon: (props: any) => <TimeIcon {...props} />,
        color: Colors.darkGrey,
        borderColor: Colors.grey,
    },
    paid: {
        id: 2,
        name: 'Оплачено',
        createIcon: (props: any) => <CheckIcon {...props} />,
        color: Colors.green,
        borderColor: Colors.lightGreen,
    },
    refusal: {
        id: 3,
        name: 'Отказано',
        createIcon: (props: any) => <NotAllowedIcon {...props} />,
        color: Colors.red,
        borderColor: Colors.lightRed,
    },
};
