import {
    Alert,
    AlertIcon,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    ModalBody,
    ModalFooter,
    Text,
} from '@chakra-ui/react';
import Colors from '../../../config/colors';
import { useRef } from 'react';
import Loader from '../../loader/Loader';

interface Props {
    email: string;
    setEmail: (email: string) => void;
    addEmail: () => void;
    isLoadingEditUser: boolean;
    errorMessage: string | null;
}

export default function EmailDialog({
    email,
    setEmail,
    addEmail,
    isLoadingEditUser,
    errorMessage,
}: Props) {
    const initialRef = useRef(null);

    return (
        <>
            <ModalBody pb={6} display="flex" flexDirection="column" gap="5">
                <FormControl>
                    <FormLabel>Введите email</FormLabel>
                    <Input
                        ref={initialRef}
                        placeholder="example@gmail.com"
                        value={email}
                        isDisabled={isLoadingEditUser}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                {errorMessage && (
                    <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                    </Alert>
                )}
                {!(errorMessage && isLoadingEditUser) && (
                    <Alert status="info">
                        <AlertIcon />
                        Адрес почты необходим для получения товара
                    </Alert>
                )}
            </ModalBody>

            <ModalFooter display="flex" gap="7px" justifyContent="space-between">
                {isLoadingEditUser && <Loader size="md" />}
                <Box></Box>
                <Box display="flex" gap="3">
                    <Button
                        bg={Colors.orange}
                        color="#fff"
                        fontWeight="500"
                        onClick={addEmail}
                        isDisabled={isLoadingEditUser}>
                        Добавить
                    </Button>
                    <Button fontWeight="500">Закрыть</Button>
                </Box>
            </ModalFooter>
        </>
    );
}
