import { Box, Progress, Text } from '@chakra-ui/react';
import SuccessAnimation from '../../successAnimation/SuccessAnimation';
import Colors from '../../../config/colors';

export default function SuccessDialog() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="60px"
            padding="30px 0px 0px 0px">
            <Box display="flex" flexDirection="column" gap="25px">
                <SuccessAnimation />
                <Text color={Colors.darkGrey} fontSize="18px">
                    Адрес почты был успешно добавлен
                </Text>
            </Box>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                <Text color={Colors.darkGrey}>Перенаправление на страницу оплаты</Text>
                <Box mt="20px" width="100%">
                    <Progress
                        size="sm"
                        isIndeterminate
                        borderRadius="0px 0px 6px 6px"
                        color="#fff"
                    />
                </Box>
            </Box>
        </Box>
    );
}
