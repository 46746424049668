import { Box, Tag, TagLabel } from '@chakra-ui/react';
import { ISupportStatus } from '../../../../../interfaces';
import { SUPPORT_STATUS_STYLES, SUPPORT_STATUS_ICONS } from '../../../../../config/supportMessage';

interface Props {
    supportStatus: ISupportStatus;
    hideName?: boolean;
}

export default function SupportStatus({ supportStatus, hideName = false }: Props) {
    const styles = SUPPORT_STATUS_STYLES[supportStatus.type];
    const StatusIcon = SUPPORT_STATUS_ICONS[supportStatus.type];

    return (
        <Tag
            size="sm"
            minWidth={hideName ? 'fit-content' : '144px'}
            variant="unstyled"
            borderWidth="1px"
            display="flex"
            alignItems="center"
            gap="5px"
            padding="7px 12px"
            {...styles}>
            {<StatusIcon />}
            <Box width="100%" display="flex" justifyContent="center">
                {!hideName && <TagLabel>{supportStatus.name}</TagLabel>}
            </Box>
        </Tag>
    );
}
