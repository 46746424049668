import { FC, InputHTMLAttributes, ReactNode, useLayoutEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import styles from './LoginInput.module.css';
import { Box } from '@chakra-ui/react';
import Colors from '../../../../../config/colors';

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'style'> & {
    suffix?: ReactNode;
};

const inputPadding = 20 as const;

export const LoginInput: FC<InputProps> = ({ value, placeholder, suffix, className, ...props }) => {
    return (
        <Box
            className={styles.inputWrapper}
            width="100%"
            borderRadius="10px"
            background={Colors.lightGrey}>
            <input
                className={clsx(styles.input, className)}
                style={{
                    padding: inputPadding,
                    background: 'none',
                    fontWeight: 600,
                }}
                value={value}
                placeholder={placeholder}
                {...props}
            />
        </Box>
    );
};
