import { Box, Text } from '@chakra-ui/react';
import Colors from '../../config/colors';

interface Props {
    name: string;
    createIcon?: (props: any) => React.ReactElement;
    isActive: boolean;
}

export default function NavbarItem({ createIcon, name, isActive }: Props) {
    const color = isActive ? Colors.orange : Colors.darkGrey;

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            color={color}
            height="100%"
            gap="3px">
            <Box display="flex" alignItems="center" height="100%">
                {createIcon && createIcon({ color })}
            </Box>
            <Text fontSize="12px" fontWeight="500">
                {name}
            </Text>
        </Box>
    );
}
