import { extendTheme } from '@chakra-ui/react';

import '@fontsource/manrope';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    semanticTokens: {
        colors: {
            error: 'red.500',
            orange: '#F59622',
            lightGrey: '#F59622',
            grey: '#EFEEF4',
            darkGrey: '#8E8E8E',
        },
    },
};

const theme = extendTheme({ config });

theme.fonts = {
    heading: `'Manrope', sans-serif`,
    body: `'Manrope', sans-serif`,
};

export default theme;
