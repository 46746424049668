import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChevronLeftIcon, Search2Icon } from '@chakra-ui/icons';

import Colors from '../../config/colors';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../hooks/actions';
import { useAppSelector } from '../../hooks/redux';

interface Props {
    showBackButton?: boolean;
}

export default function SearchInput({ showBackButton = true }: Props) {
    const navigate = useNavigate();
    const { setSearch } = useActions();
    const searchValue = useAppSelector((state) => state.search.name);

    const goToPreviousPage = () => {
        navigate(-1);
        setSearch('');
    };

    const onChange = (name: string) => {
        setSearch(name);
    };

    return (
        <Box display="flex" alignItems="center" width="100%" gap="14px">
            {showBackButton && <ChevronLeftIcon boxSize={6} onClick={() => goToPreviousPage()} />}

            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={<Search2Icon color={Colors.darkGrey} />}
                    width="34px"
                    height="36px"
                />
                <Input
                    type="text"
                    placeholder="Название товара ..."
                    bg={Colors.grey}
                    borderRadius="10px"
                    border="none"
                    padding="6px 30px"
                    fontSize="16px"
                    variant="unstyled"
                    onChange={(e) => onChange(e.target.value)}
                    value={searchValue}
                />
            </InputGroup>
        </Box>
    );
}
