import { useEffect } from 'react';
import {
    useAuthUserMutation,
    useRegistrationUserMutation,
} from '../../../redux/platimarket/platimarket.api';
import { useTelegram } from '../../../hooks/useTelegram';
import { useActions } from '../../../hooks/actions';
import { useNavigate } from 'react-router-dom';

export default function Auth() {
    const navigate = useNavigate();
    const { initData } = useTelegram();
    const { setUser } = useActions();

    const [
        authUser,
        { data: authData, isSuccess: isAuthSuccess, isError: isAuthError, error: authError },
    ] = useAuthUserMutation();

    const [
        registrationUser,
        { data: regData, isSuccess: isRegSuccess, isError: isRegError, error: regError },
    ] = useRegistrationUserMutation();

    useEffect(() => {
        authUser({
            initData,
        });
    }, []);

    useEffect(() => {
        if (isAuthSuccess) {
            setUser({ accessToken: authData?.accessToken! });
            navigate('/catalog');
        }
        if (isRegSuccess) {
            setUser({ accessToken: regData?.accessToken! });
            navigate('/catalog');
        }
    }, [isAuthSuccess, isRegSuccess]);

    useEffect(() => {
        const statusCode = (authError as any)?.status;

        if (statusCode === 404) {
            registrationUser({
                initData,
            });
        }
        if (statusCode === 401) {
            authUser({
                initData,
            });
        }
    }, [authError]);

    return <></>;
}
