import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    IAaioInvoice,
    IAuthUser,
    ICategory,
    ICryptomusInvoice,
    IGetPlayWalletCurrency,
    IGetPlayWalletRate,
    IOrder,
    IProduct,
    IProductsCategory,
    ISubcategory,
    ISupportMessage,
    ISupportStatus,
    ITonkeeperInvoice,
    IUser,
    IUserPhoto,
} from '../../interfaces';
import {
    IGetCategoryParams,
    IGetProductParams,
    IGetProductsCategoryParams,
    IGetProductsParams,
    IGetSubcategoryParams,
    IGetSupportMessagesParams,
    IGetUserPhotoParams,
} from './interfaces/query-params';
import {
    IPatchEditUserBody,
    IPostAaioInvoiceBody,
    IPostAuthUserBody,
    IPostCryptomusInvoiceBody,
    IPostSendMessageBody,
    IPostTonkeeperInvoiceBody,
} from './interfaces/query-body';
import { RootState } from '../store';

export const platimarketApi = createApi({
    reducerPath: 'platimarket/api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            let currentAccessToken = (getState() as RootState).auth.accessToken;

            if (!currentAccessToken) {
                const savedUser = localStorage.getItem('user');

                if (savedUser) {
                    const user = JSON.parse(savedUser);
                    currentAccessToken = user.accessToken;
                }
            }
            headers.set('authorization', `Bearer ${currentAccessToken}`);
            return headers;
        },
    }),
    endpoints: (build) => ({
        authUser: build.mutation<IAuthUser, IPostAuthUserBody>({
            query: (arg) => ({
                url: 'user/auth',
                method: 'POST',
                body: arg,
            }),
        }),
        registrationUser: build.mutation<IAuthUser, IPostAuthUserBody>({
            query: (arg) => ({
                url: 'user/registration',
                method: 'POST',
                body: arg,
            }),
        }),
        getCategories: build.query<ICategory[], IGetCategoryParams>({
            query: (arg) => ({
                url: 'category',
                params: arg,
            }),
        }),
        getProductCategories: build.query<IProductsCategory[], IGetProductsCategoryParams>({
            query: (arg) => ({
                url: 'category/product',
                params: arg,
            }),
        }),
        getSubcategories: build.query<ISubcategory[], IGetSubcategoryParams>({
            query: (arg) => ({
                url: 'subcategory',
                params: arg,
            }),
        }),
        getProducts: build.query<IProduct[], IGetProductsParams>({
            query: (arg) => ({
                url: 'product',
                params: arg,
            }),
        }),
        getProduct: build.query<IProduct, number>({
            query: (id) => ({
                url: 'product/' + id,
            }),
        }),
        getUserPhoto: build.query<IUserPhoto, IGetUserPhotoParams>({
            query: (arg) => ({
                url: 'user/photo',
                params: arg,
            }),
        }),
        getOrders: build.query<IOrder[], void>({
            query: () => ({
                url: 'order',
            }),
        }),
        getUser: build.query<IUser, void>({
            query: () => ({
                url: 'user',
            }),
        }),
        editUser: build.mutation<IUser, IPatchEditUserBody>({
            query: (arg) => ({
                url: 'user',
                method: 'PATCH',
                body: arg,
            }),
        }),
        sendMessage: build.mutation<ISupportMessage, IPostSendMessageBody>({
            query: (arg) => ({
                url: 'support/message',
                method: 'POST',
                body: arg,
            }),
        }),
        getSupportMessages: build.query<ISupportMessage[], IGetSupportMessagesParams>({
            query: (arg) => ({
                url: 'support/message/user',
                params: arg,
            }),
        }),
        getSupportStatuses: build.query<ISupportStatus[], void>({
            query: () => ({
                url: 'support/status',
            }),
        }),
        createCryptomusInvoice: build.mutation<ICryptomusInvoice, IPostCryptomusInvoiceBody>({
            query: (arg) => ({
                url: 'payment/cryptomus/checkout',
                method: 'POST',
                body: arg,
            }),
        }),
        getCurrencies: build.query<IGetPlayWalletCurrency, void>({
            query: () => ({
                url: 'https://api.pwbackend.com/currency',
            }),
        }),
        getRate: build.query<IGetPlayWalletRate, void>({
            query: () => ({
                url: 'https://api.pwbackend.com/rates',
            }),
        }),
        createTonkeeperInvoice: build.mutation<ITonkeeperInvoice, IPostTonkeeperInvoiceBody>({
            query: (arg) => ({
                url: 'payment/tonkeeper/checkout',
                method: 'POST',
                body: arg,
            }),
        }),
        createAaioInvoice: build.mutation<IAaioInvoice, IPostAaioInvoiceBody>({
            query: (arg) => ({
                url: 'payment/aaio/checkout',
                method: 'POST',
                body: arg,
            }),
        }),
    }),
});

export const {
    useCreateAaioInvoiceMutation,
    useCreateTonkeeperInvoiceMutation,
    useGetRateQuery,
    useGetCurrenciesQuery,
    useCreateCryptomusInvoiceMutation,
    useGetSupportStatusesQuery,
    useLazyGetSupportMessagesQuery,
    useSendMessageMutation,
    useEditUserMutation,
    useGetUserQuery,
    useAuthUserMutation,
    useRegistrationUserMutation,
    useGetCategoriesQuery,
    useGetProductCategoriesQuery,
    useLazyGetProductsQuery,
    useGetSubcategoriesQuery,
    useGetProductsQuery,
    useGetProductQuery,
    useLazyGetUserPhotoQuery,
    useGetUserPhotoQuery,
    useGetOrdersQuery,
} = platimarketApi;
