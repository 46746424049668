import { Link } from 'react-router-dom';
import { Box, ListItem, Text } from '@chakra-ui/react';
import { IRoute } from '../../interfaces';

import Colors from '../../config/colors';
import NavbarItem from '../navbarItem/NavbarItem';

interface Props extends IRoute {
    // onClick: (path: string) => void;
    isActive: boolean;
}

export default function NavbarListItem({ path, name, createIcon, isActive }: Props) {
    return (
        <ListItem>
            <Link to={path}>
                <NavbarItem name={name} createIcon={createIcon} isActive={isActive} />
            </Link>
        </ListItem>
    );
}
