import ConvertIcon from './icons/convert.svg';
import { CurrencyInput } from '../currencyInput/CurrencyInput';
import { Box, Image, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Colors from '../../../../../config/colors';

interface Props {
    onChangeAmount: (value: number) => void;
    currency: string;
    amount: number;
    onSwap: () => void;
    isSwap: boolean;
    inTON: number;
    setInTON: (value: number) => void;
}
export default function AmountInput({
    onChangeAmount,
    currency,
    amount,
    onSwap,
    isSwap,
    inTON,
    setInTON,
}: Props) {
    const [value, setValue] = useState<number | string>(amount);

    const onChangeValue = (value: string) => {
        if (value?.includes(',')) {
            // if (isSwap) {
            //     setInTON(value.replace(',', '.'));
            // } else {
            //     setValue(value.replace(',', '.'));
            // }
            setValue(value.replace(',', '.'));
        }
        if (Number(value) === +value || value.includes('.') || !value.length) {
            // if (isSwap) {
            //     onChangeAmount(+value.replace('.', ''));
            //     console.log(value);
            //     console.log(inTON);
            //     setValue(inTON);

            //     // setInTON((+value.replace('.', '')).toString());
            // } else {
            //     setValue(value);
            // }
            const amount = +value;
            // const currentValue = value.length ? value : '0';

            onChangeAmount(amount);
            setValue(value);
        }
    };

    useEffect(() => {
        if (isSwap) {
            setValue(inTON);
        } else {
            setValue(amount);
        }
    }, [isSwap]);
    return (
        <InputGroup width="100%" borderRadius="10px" background={Colors.lightGrey}>
            <CurrencyInput
                value={value}
                suffix={isSwap ? 'TON' : currency}
                onChange={(e) => onChangeValue(e.target.value)}
            />
            <InputRightElement width="80px" display="flex" height="100%" onClick={() => onSwap()}>
                <Box height="50px">
                    <Image
                        src={ConvertIcon}
                        alt="convert-icon"
                        background="rgba(55, 159, 239, 0.1)"
                        height="100%"
                        borderRadius="6px"
                    />
                </Box>
            </InputRightElement>
        </InputGroup>
    );
}
