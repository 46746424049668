import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import routes from '../../config/routes';
import Layout from '../layout/Layout';

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Navigate to="/catalog" />} />
                    {routes.map(({ path, element }) => (
                        <Route path={path} element={element} key={path} />
                    ))}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
