import { Box, SimpleGrid } from '@chakra-ui/react';

import { IProduct } from '../../interfaces';
import ProductCard from '../productCard/ProductCard';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { cardLazyVariants } from '../../config/animation';

interface Props {
    products: IProduct[];
}

export default function ProductList({ products }: Props) {
    return (
        <Box display="flex" flexDirection="column" gap="3" fontWeight="500">
            <AnimatePresence>
                {products.map((product, index) => (
                    <motion.div
                        key={index}
                        variants={cardLazyVariants}
                        custom={index}
                        initial="hidden"
                        animate="visible">
                        <Link
                            to={'/product/' + product.subcategory.id}
                            state={{ pageName: product.subcategory.name }}
                            key={product.name}>
                            <ProductCard product={product} />
                        </Link>
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
}
