import { Box, Container, Text } from '@chakra-ui/react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Carousel from '../../carousel/Carousel';
import Colors from '../../../config/colors';
import BuyButton from '../../buyButton/buyButton';
import findRoute from '../../../utils/findRoute';
import EmptyAlert from '../../emptyAlert/EmptyAlert';
import { useGetUserQuery, useGetProductsQuery } from '../../../redux/platimarket/platimarket.api';

import { AnimatePresence, motion } from 'framer-motion';
import Parser from 'html-react-parser';
import { AGENT_ID } from '../../../config/user';
import { useState } from 'react';
import EmailModal from '../../emailModal/EmailModal';

import '../../../styles/shine.css';
import Loader from '../../loader/Loader';

const headerVariants = {
    hidden: {
        opacity: 0,
        y: 30,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.2,
        },
    },
};

const infoVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.4,
        },
    },
};

export default function Product() {
    const params = useParams();
    const subcategoryId = Number(params.subcategoryId);

    const { isLoading, isError, data } = useGetProductsQuery({ subcategoryId, isMain: true });

    const { data: aboutUser } = useGetUserQuery();

    const product = data && data[0];

    const location = useLocation();
    const pathName = location.pathname;

    const findedRoute = findRoute(pathName);
    const emptyMessage = findedRoute?.emptyMessage;

    const [isOpenModal, setIsOpenModal] = useState(false);

    const paymentURL = `https://digiseller.market/asp/pay_wm.asp?id_d=${product?.productId}&ai=${AGENT_ID}`;

    return (
        <Container
            display="flex"
            flexDirection="column"
            gap="4"
            fontWeight="500"
            fontSize="16px"
            height="calc(100vh - 70px)">
            <EmailModal
                isOpen={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                redirectURL={paymentURL}
            />
            {isLoading && <Loader position="absolute" margin="auto" inset="0" />}
            {!isLoading && !product && <EmptyAlert message={emptyMessage} />}
            {product && (
                <>
                    <Box
                        position="fixed"
                        display="flex"
                        alignItems="center"
                        bottom="6"
                        zIndex="6"
                        left="3"
                        right="3">
                        {aboutUser?.email ? (
                            <BuyButton
                                name="Купить сейчас"
                                className="shine"
                                as={Link}
                                to={paymentURL + '&email=' + aboutUser.email}
                            />
                        ) : (
                            <BuyButton name="Купить сейчас" onClick={() => setIsOpenModal(true)} />
                        )}
                    </Box>
                    <Box width="100%">
                        <Carousel images={product?.images ?? []} />
                    </Box>
                    <AnimatePresence>
                        <motion.div variants={headerVariants} initial="hidden" animate="visible">
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="column"
                                gap="11px">
                                <Box display="flex" flexDirection="column" gap="1">
                                    <Text fontSize="14px">{product?.name}</Text>
                                    <Text fontSize="18px">
                                        {product?.price.toLocaleString()} RUB
                                    </Text>
                                </Box>
                                <Box display="flex" gap="15px" flexWrap="wrap" fontSize="12px">
                                    <Text color={Colors.darkGrey}>Продавец: {product?.seller}</Text>
                                    <Text color={Colors.darkGrey}>
                                        Продаж: {product?.countSales}
                                    </Text>
                                    <Text color={Colors.darkGrey}>
                                        Возвратов: {product?.countReturns}
                                    </Text>
                                </Box>
                            </Box>
                        </motion.div>
                    </AnimatePresence>

                    <AnimatePresence>
                        <motion.div variants={infoVariants} initial="hidden" animate="visible">
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="5"
                                background="gray.50"
                                position="absolute"
                                zIndex="2"
                                top="560px"
                                left="0px"
                                right="0px"
                                padding="30px"
                                minHeight="100%"
                                paddingBottom="150px"
                                alignItems="center">
                                <Box display="flex" flexDirection="column" gap="16px">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap="6px"
                                        maxWidth="522px">
                                        <Text color={Colors.darkGrey} fontSize="16px">
                                            Описание товара
                                        </Text>

                                        <Text textAlign="justify" fontSize="14px">
                                            {Parser(product?.description ?? '')}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </motion.div>
                    </AnimatePresence>
                </>
            )}
        </Container>
    );
}
