import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription, AlertTitle, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import JumpButton from '../jumpButton/JumpButton';
import SupportButton from '../supportButton/SupportButton';
import SupportModal from '../supportModal/SupportModal';
import { useState } from 'react';

interface Props {
    message?: string;
    isSearchButton?: boolean;
}

export default function SupportAlert({ message = 'Не найдено' }: Props) {
    const [isOpenModal, setIsOpenModal] = useState(false);

    return (
        <Alert
            variant="style"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="100vh"
            gap="4"
            paddingBottom="230px">
            <Box>
                <AlertTitle mt={4} mb={1} fontSize="lg">
                    {message}
                </AlertTitle>
            </Box>
            <AlertDescription maxWidth="sm">
                <SupportModal isOpen={isOpenModal} setIsOpenModal={setIsOpenModal} />
                <SupportButton
                    name="Моего товара нет в списке"
                    padding="24px 70px"
                    borderRadius="10px"
                    onClick={() => setIsOpenModal(true)}
                />
            </AlertDescription>
        </Alert>
    );
}
