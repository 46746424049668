enum Colors {
    darkGrey = '#84838A',
    grey = '#EFEEF4',
    lightGrey = '#F7FAFC',
    lightOrange = '#FCE5CA',
    orange = '#F59622',
    lightBlue = '#DCE6FF',
    blue = 'rgba(55, 159, 239, 1)',
    lightGreen = '#B4EED9',
    darkGreen = '#45B39D',
    green = '#80DCBC',
    lightRed = '#FFE1E1',
    red = '#FF8F8F',
}

export default Colors;
