import { Box, Card, CardBody, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { IProduct } from '../../interfaces';
import { Link } from 'react-router-dom';
import Colors from '../../config/colors';
import truncate from '../../utils/truncate';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    product: IProduct;
}

export default function ProductCard({ product }: Props) {
    const firstImageURL = product.images[0]?.name;

    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="unstyled"
            boxShadow="rgba(149, 157, 165, 0.25) 0px 3px 12px"
            borderRadius="10px">
            <CardBody padding="20px">
                <Box
                    height="100px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="10px">
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <Text fontSize="14px" fontWeight="600">
                            {product.price?.toLocaleString()} RUB
                        </Text>
                        <Text fontSize="14px">{truncate(product.name, 100)}</Text>
                        <Text color={Colors.darkGrey} fontSize="12px" mt="3px">
                            {product.seller}
                        </Text>
                    </Box>
                    <LazyLoadImage
                        src={firstImageURL ?? ''}
                        effect="blur"
                        style={{
                            maxWidth: '100px',
                            borderRadius: '5px',
                            display: 'block',
                            maxHeight: '100px',
                        }}
                    />
                </Box>
            </CardBody>
        </Card>
    );
}
