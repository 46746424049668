import { Box, Container, Text } from '@chakra-ui/react';
import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function TonkeeperAuthPage() {
    const wallet = useTonWallet();

    return (
        <Container display="flex" alignItems="center" height="100vh" justifyContent="center">
            <Box
                paddingBottom="230px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="5">
                <Box display="flex" flexDirection="column" gap="9px" alignItems="center">
                    <LazyLoadImage
                        effect="blur"
                        src={process.env.PUBLIC_URL + '/images/replenishment/tonkeeper.png'}
                        style={{ height: '100px', borderRadius: '10px' }}
                    />
                    <Text fontSize="16px">Tonkeeper</Text>
                </Box>
                <Box>{!wallet && <TonConnectButton />}</Box>
            </Box>
        </Container>
    );
}
