import { Box, Container } from '@chakra-ui/react';

import { useLocation, useParams } from 'react-router-dom';
import EmptyAlert from '../../emptyAlert/EmptyAlert';
import findRoute from '../../../utils/findRoute';
import ProductList from '../../productList/ProductList';
import { useGetProductsQuery } from '../../../redux/platimarket/platimarket.api';
import Loader from '../../loader/Loader';

export default function Products() {
    const location = useLocation();
    const pathName = location.pathname;

    const findedRoute = findRoute(pathName);
    const emptyMessage = findedRoute?.emptyMessage;

    const params = useParams();
    const subcategoryId = Number(params.subcategoryId);

    const { isLoading, isError, data } = useGetProductsQuery({
        subcategoryId,
        orderBy: 'position',
        sortBy: 'ASC',
    });

    return (
        <Container>
            {isLoading ? (
                <Box display="flex" justifyContent="center" mt="30px">
                    <Loader />
                </Box>
            ) : data?.length ? (
                <ProductList products={data}></ProductList>
            ) : (
                <EmptyAlert message={emptyMessage} />
            )}
        </Container>
    );
}
