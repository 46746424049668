import { Box, Button, Container, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginInput } from './compontents/loginInput/LoginInput';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import SaveLoginSwitch from './compontents/saveLoginSwitch/SaveLoginSwitch';
import Colors from '../../../config/colors';

interface Props {
    nextPage: string;
}

export default function SteamLoginPage({ nextPage }: Props) {
    const { login, isSave } = useAppSelector((state) => state.replenishment.steam);

    const { setFieldSteamReplenishment } = useActions();

    const isContinue = login?.length;

    const onChangeLogin = (value: string) => {
        setFieldSteamReplenishment({ field: 'login', value });
    };

    const saveLogin = (isSave: boolean) => {
        if (login?.length) {
            setFieldSteamReplenishment({ field: 'isSave', value: isSave });
        }
    };

    useEffect(() => {
        if (login?.length) {
            let currentLogin: string | null = login;

            if (!isSave) {
                currentLogin = null;
            }

            localStorage.setItem('steam', JSON.stringify({ login: currentLogin }));
        }
        if (login === null) {
            const steam = JSON.parse(localStorage.getItem('steam') ?? '{"login": ""}');
            const savedLogin = steam.login;

            if (savedLogin) {
                onChangeLogin(savedLogin);
            }
        }
    }, [login, isSave]);

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="4">
                <Box>
                    <Text fontSize="20px">Логин Steam</Text>
                    <Text fontWeight="300">Введите логин Steam от вашего аккаунта</Text>
                </Box>
                <Box>
                    <LoginInput
                        placeholder="Укажите логин Steam"
                        onChange={(e) => onChangeLogin(e.target.value)}
                        value={login ?? ''}
                    />
                </Box>
                <SaveLoginSwitch saveLogin={saveLogin} isSave={!!(isSave && login?.length)} />
                <Box
                    position="fixed"
                    display="flex"
                    alignItems="center"
                    bottom="0"
                    zIndex="3"
                    left="0"
                    right="0">
                    <Button
                        isDisabled={!isContinue}
                        pointerEvents={isContinue ? 'auto' : 'none'}
                        as={Link}
                        to={nextPage}
                        variant="solid"
                        color="white"
                        background={Colors.blue}
                        fontSize="16px"
                        fontWeight="500"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="12px"
                        width="100%"
                        padding="30px 0px"
                        borderRadius="0px">
                        <Text>Продолжить</Text>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
