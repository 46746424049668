import { CheckIcon, TimeIcon } from '@chakra-ui/icons';
import Colors from './colors';

interface ISupportStatus {
    [key: string]: any;
}

export const SUPPORT_STATUS_STYLES: ISupportStatus = {
    awaiting: {
        color: Colors.blue,
        borderColor: Colors.lightBlue,
    },
    received: {
        color: Colors.green,
        borderColor: Colors.lightGreen,
    },
};

export const SUPPORT_STATUS_ICONS: ISupportStatus = {
    awaiting: TimeIcon,
    received: CheckIcon,
};
