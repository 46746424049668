import Catalog from '../components/pages/catalog/Catalog';

import { IRoute } from '../interfaces';

import CatalogIcon from '../images/navbar/CatalogIcon';
import Orders from '../components/pages/orders/Orders';
import Product from '../components/pages/product/Product';
import ProductsCategory from '../components/pages/productsCategory/productsCategory';
import Subcategories from '../components/pages/subcategories/Subcategories';
import Replenishment from '../components/pages/replenishment/Replenishment';
import Products from '../components/pages/products/Products';
import TonkeeperAmountPage from '../components/pages/tonkeeper/TonkeeperAmountPage';
import TonkeeperLoginPage from '../components/pages/tonkeeper/TonkeeperLoginPage';
import Auth from '../components/pages/auth/Auth';
import SupportMessagesPage from '../components/pages/supportMessages/SupportMessagesPage';
import CurrencyPage from '../components/pages/currency/CurrencyPage';
import TonkeeperSteamConfirm from '../components/pages/tonkeeperSteamConfirm/TonkeeperSteamConfirmPage';
import CryptomusLoginPage from '../components/pages/cryptomus/CryptomusLoginPage';
import CryptomusAmountPage from '../components/pages/cryptomus/CryptomusAmountPage';
import CryptomusSteamConfirmPage from '../components/pages/cryptomusSteamConfirm/cryptomusSteamConfirmPage';
import AaioLoginPage from '../components/pages/aaio/AaioLoginPage';
import AaioAmountPage from '../components/pages/aaio/AaioAmountPage';
import AaioSteamConfirmPage from '../components/pages/aaioSteamConfirm/aaioSteamConfirmPage';
import TonkeeperPage from '../components/pages/tonkeeper/TonkeeperPage';

const routes: IRoute[] = [
    {
        path: '/catalog',
        name: 'Каталог',
        createIcon: (props: any) => <CatalogIcon {...props} />,
        element: <Catalog />,
        isVisible: true,
        showNavbar: true,
        showSearch: true,
        emptyMessage: 'Категории не найдены',
    },
    {
        path: '/orders',
        name: 'Мои заказы',
        element: <Orders />,
        isVisible: false,
        showNavbar: true,
        showSearch: false,
        emptyMessage: 'Заказы не найдены',
    },
    {
        path: '/support',
        name: 'Поддержка',
        element: <SupportMessagesPage />,
        isVisible: false,
        showNavbar: true,
        showSearch: false,
        emptyMessage: 'Сообщения не найдены',
    },
    {
        path: '/product/:subcategoryId',
        name: 'Товар',
        element: <Product />,
        isVisible: false,
        showSearch: false,
        emptyMessage: 'Товар не найден',
    },
    {
        path: '/subcategory/product/:subcategoryId',
        name: 'Товары',
        element: <Products />,
        isVisible: false,
        showNavbar: true,
        showSearch: false,
        emptyMessage: 'Товары не найден',
    },
    {
        path: '/category/:categoryId',
        name: 'Товары',
        element: <ProductsCategory />,
        isVisible: false,
        showNavbar: true,
        showSearch: true,
        emptyMessage: 'Товары не найдены',
    },
    {
        path: '/subcategory/:productsCategoryId',
        name: 'Подкатегории',
        element: <Subcategories />,
        isVisible: false,
        showNavbar: true,
        showSearch: false,
        emptyMessage: 'Подкатегории не найдены',
    },
    {
        path: '/replenishment',
        name: 'Пополнение',
        element: <Replenishment />,
        showNavbar: true,
        isVisible: false,
        showSearch: false,
    },
    {
        path: '/tonkeeper',
        name: 'Tonkeeper',
        element: <TonkeeperPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/tonkeeper/steam/login',
        name: 'Tonkeeper',
        element: <TonkeeperLoginPage />,
        isVisible: false,
        showNavbar: true,
        showSearch: false,
    },
    {
        path: '/tonkeeper/steam/amount',
        name: 'Tonkeeper',
        element: <TonkeeperAmountPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/tonkeeper/steam/confirm',
        name: 'Tonkeeper',
        element: <TonkeeperSteamConfirm />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/cryptomus/steam/login',
        name: 'Cryptomus',
        element: <CryptomusLoginPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/cryptomus/steam/amount',
        name: 'Cryptomus',
        element: <CryptomusAmountPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/cryptomus/steam/confirm',
        name: 'Tonkeeper',
        element: <CryptomusSteamConfirmPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/aaio/steam/login',
        name: 'Aaio',
        element: <AaioLoginPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/aaio/steam/amount',
        name: 'Aaio',
        element: <AaioAmountPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/aaio/steam/confirm',
        name: 'Aaio',
        element: <AaioSteamConfirmPage />,
        isVisible: false,
        showNavbar: false,
        showSearch: false,
    },
    {
        path: '/auth',
        name: 'Авторизация',
        element: <Auth />,
        isHideBreadcrumb: true,
        isVisible: false,
        showSearch: false,
    },
    {
        path: '/currency',
        name: 'Выбор валюты',
        element: <CurrencyPage />,
        isVisible: false,
        showSearch: false,
    },
];

export default routes;
