import { Box, Container, Grid, GridItem } from '@chakra-ui/react';

import Header from '../header/Header';
import Navbar from '../navbar/Navbar';

import { Outlet, useNavigate } from 'react-router-dom';
import Profile from '../profile/Profile';

import { useEffect } from 'react';
import { useUser } from '../../hooks/useUser';

export default function Layout() {
    const { user, isAuth, setUser } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth) {
            setUser(user);
        } else {
            navigate('/auth');
        }
    }, []);

    return (
        <Container display="flex" justifyContent="center">
            <Box width="100%">
                <Grid
                    templateAreas={`"header"
                  "main"
                  "nav"`}
                    gridTemplateRows={'70px 1fr 70px'}
                    gridTemplateColumns={'100%'}
                    color="blackAlpha.700"
                    fontWeight="bold">
                    <GridItem area={'header'}>
                        <Header />
                    </GridItem>
                    <GridItem area={'main'}>
                        <Outlet />
                    </GridItem>
                    <GridItem area={'nav'}>
                        <Profile />

                        <Navbar />
                    </GridItem>
                </Grid>
            </Box>
        </Container>
    );
}
