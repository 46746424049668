interface ITonConnectUIProviderConfig {
    [x: string]: any;
}

export const tonConnectUIProviderConfig: ITonConnectUIProviderConfig = {
    manifestUrl: 'https://ton-connect.github.io/demo-dapp-with-react-ui/tonconnect-manifest.json',
    actionsConfiguration: {
        modals: 'all',
        notifications: 'all',
    },
    language: 'ru',
};
