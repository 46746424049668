import { Avatar, Box, Tag, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Colors from '../../../../../config/colors';
import MessageAvatar from '../messageAvatar/MessageAvatar';

interface Props {
    sentAt: any;
    userPhotoURL?: string | null;
    supportMessage: any;
    isReply?: boolean;
}

export default function SupportMessage({
    sentAt,
    userPhotoURL,
    supportMessage,
    isReply = false,
}: Props) {
    const sentAtString = sentAt ? `${sentAt.getHours()}:${sentAt.getMinutes()}` : 'Неизестно';

    return (
        <Box display="flex" flexDirection="column" gap="5">
            <Box display="flex" alignItems="center" justifyContent="center">
                <Tag padding="6px 20px">
                    {sentAt.toLocaleDateString('ru-RU', {
                        day: 'numeric',
                        month: 'long',
                    })}
                </Tag>
            </Box>

            {isReply ? (
                <Box display="flex" gap="3">
                    <Box width="60px">
                        <Avatar background={Colors.blue} name="Support" />
                    </Box>
                    <Box width="100%" display="flex" flexDirection="column" gap="2">
                        <Box display="flex" gap="2">
                            <Text color={Colors.blue}>@Support</Text>
                            <Text color={Colors.darkGrey}>{sentAtString}</Text>
                        </Box>

                        <Text padding="8px 16px" borderWidth="1px" borderRadius="8px">
                            {supportMessage?.replyMessage ?? 'Сообщение не найдено'}
                        </Text>
                    </Box>
                </Box>
            ) : (
                <Box display="flex" gap="3">
                    <Box width="60px">
                        <MessageAvatar src={userPhotoURL ?? null} />
                    </Box>
                    <Box width="100%" display="flex" flexDirection="column" gap="2">
                        <Box display="flex" gap="2">
                            <Link to={'https://t.me/' + supportMessage?.username ?? ''}>
                                @{supportMessage?.username ?? ''}
                            </Link>
                            <Text color={Colors.darkGrey}>{sentAtString}</Text>
                        </Box>
                        <Text padding="8px 16px" borderWidth="1px" borderRadius="8px">
                            {supportMessage?.message ?? 'Сообщение не найдено'}
                        </Text>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
