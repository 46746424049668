import { Button, Text } from '@chakra-ui/react';
import Colors from '../../config/colors';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface Props {
    name: string;
    fontSize?: string;
    padding?: string;
    [x: string]: any;
}

export default function JumpButton({ name, ...props }: Props) {
    return (
        <Button
            variant="solid"
            border="none"
            color={Colors.blue}
            fontSize="14px"
            fontWeight="500"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="12px"
            width="100%"
            {...props}>
            <Text>{name}</Text>
            <ArrowForwardIcon boxSize={4} />
        </Button>
    );
}
