import { Box, Button, Container, List, ListItem, SimpleGrid } from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import OrderList from '../../orderList/OrderList';
import { useLocation } from 'react-router-dom';
import findRoute from '../../../utils/findRoute';
import EmptyAlert from '../../emptyAlert/EmptyAlert';
import {
    useLazyGetSupportMessagesQuery,
    useGetSupportStatusesQuery,
} from '../../../redux/platimarket/platimarket.api';
import Loader from '../../loader/Loader';
import SupportMessageList from './components/SupportMessageList';
import Colors from '../../../config/colors';
import { current } from '@reduxjs/toolkit';
import SupportAlert from '../../supportAlert/SupportAlert';
import ModalShow from './components/modalShow/ModalShow';

export default function SupportMessagesPage() {
    const [
        getSupportMessages,
        {
            isFetching: isLoadingSupportMessages,
            isError: isErrorSupportMessages,
            data: supportMessages,
        },
    ] = useLazyGetSupportMessagesQuery();
    const {
        isLoading: isLoadingSupportStatuses,
        isError: isErrorSupportStatuses,
        isSuccess: isSuccessSupportStatuses,
        data: supportStatuses,
    } = useGetSupportStatusesQuery();

    const location = useLocation();
    const pathName = location.pathname;

    const findedRoute = findRoute(pathName);
    const emptyMessage = findedRoute?.emptyMessage;

    const [currentStatus, setCurrentStatus] = useState('received');

    const selectStatus = (statusId: number) => {
        const status = supportStatuses?.find((item) => item.id === statusId);
        setCurrentStatus(status?.type!);
    };

    useEffect(() => {
        console.log(currentStatus);
        if (supportStatuses?.length) {
            const status = supportStatuses?.find((item) => item.type === currentStatus);
            getSupportMessages({ supportStatusId: status?.id });
        }
    }, [isSuccessSupportStatuses, currentStatus]);

    return (
        <Container paddingBottom="40px" display="flex" flexDirection="column" gap="3">
            <ModalShow />
            <SimpleGrid columns={2} spacing={2}>
                {supportStatuses?.map((item) => (
                    <Button
                        variant="none"
                        onClick={() => selectStatus(item.id)}
                        fontWeight="500"
                        fontSize="14px"
                        height="35px"
                        background={item.type === currentStatus ? Colors.orange : 'gray.100'}
                        color={item.type === currentStatus ? '#fff' : 'black'}>
                        {item.name}
                    </Button>
                ))}
            </SimpleGrid>
            {isLoadingSupportMessages ? (
                <Box display="flex" justifyContent="center" mt="30px">
                    <Loader />
                </Box>
            ) : supportMessages?.length ? (
                <SupportMessageList supportMessages={supportMessages} />
            ) : (
                <SupportAlert message="Задайте первый вопрос" />
            )}
        </Container>
    );
}
