const ProfileIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
        <path
            {...props}
            stroke={props.color ?? 'grey.100'}
            strokeLinecap={props.strokeLinecap ?? 'round'}
            strokeWidth={1.5}
            d="M3.738 19.5c1.325-2.21 4.23-3.724 8.762-3.724s7.437 1.513 8.762 3.724M16.1 8.1a3.6 3.6 0 1 1-7.2 0 3.6 3.6 0 0 1 7.2 0Z"
        />
    </svg>
);
export default ProfileIcon;
