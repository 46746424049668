import { Box, Card, CardBody, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { ISubcategory } from '../../interfaces';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    subcategory: ISubcategory;
}

export default function SubcategoryCard({ subcategory }: Props) {
    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow="hidden"
            variant="unstyled"
            // boxShadow="lg"
            boxShadow="rgba(149, 157, 165, 0.25) 0px 3px 12px"
            borderRadius="10px">
            <LazyLoadImage
                height="100%"
                src={process.env.PUBLIC_URL + '/images/subcategories/background.png'}
                effect="blur"
                style={{
                    position: 'absolute',
                    zIndex: '1',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    top: '0',
                    right: '0',
                    bottom: '0',
                }}
            />

            <CardBody display="flex" alignItems="center" padding="20px">
                <Box padding="12px">
                    <Text fontSize="14px" fontWeight="600">
                        {subcategory.name}
                    </Text>
                    <Text fontSize="14px">{subcategory.description}</Text>
                </Box>
            </CardBody>
        </Card>
    );
}
