import { Avatar } from '@chakra-ui/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    [key: string]: any;
    src: string | null;
    name?: string;
}

export default function MessageAvatar({ src, name = '', ...props }: Props) {
    return src ? (
        <LazyLoadImage
            style={{ objectFit: 'cover', borderRadius: '50%' }}
            src={src}
            height="100%"
            effect="blur"
            {...props}
        />
    ) : (
        <Avatar />
    );
}
