import { ChakraProvider } from '@chakra-ui/react';
import theme from './themes/main';

import './styles/main.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Router from './components/router/Router';

function App() {
    return (
        <ChakraProvider theme={theme}>
            <Router />
        </ChakraProvider>
    );
}

export default App;
