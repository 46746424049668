import { Box } from '@chakra-ui/react';

import { ISupportMessage } from '../../../../interfaces';
import SupportMessageItem from './SupportMessageItem';
import { AnimatePresence, motion } from 'framer-motion';
import { cardLazyVariants } from '../../../../config/animation';

interface Props {
    supportMessages: ISupportMessage[];
}

export default function SupportMessageList({ supportMessages }: Props) {
    return (
        <Box display="flex" flexDirection="column" gap="3">
            <AnimatePresence>
                {supportMessages.map((item: ISupportMessage, index) => (
                    <motion.div
                        key={index}
                        variants={cardLazyVariants}
                        custom={index}
                        initial="hidden"
                        animate="visible">
                        <SupportMessageItem
                            supportMessage={item}
                            key={item.id}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        </Box>
    );
}
