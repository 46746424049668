import { Box, List } from '@chakra-ui/react';

import NavbarListItem from '../navbarListItem/NavbarListItem';
import { IRoute } from '../../interfaces';
import { useActions } from '../../hooks/actions';
import { useAppSelector } from '../../hooks/redux';
import { navbarItem } from '../../config/navbar';
import { useLocation } from 'react-router-dom';
import NavbarItem from '../navbarItem/NavbarItem';

interface Props {
    routes: IRoute[];
}

export default function NavbarList({ routes }: Props) {
    const location = useLocation();
    const isOpen = useAppSelector((state) => state.navigation.isOpen);

    const { setOpen } = useActions();

    const openProfile = () => {
        setOpen(true);
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            bottom="0"
            left="0"
            right="0"
            zIndex="2"
            bg="white"
            padding="12px 0px 30px 0px">
            <List display="flex" alignItems="center" gap="7">
                {routes
                    .filter((route: any) => route.isVisible)
                    .map((route) => (
                        <NavbarListItem
                            {...route}
                            isActive={route.path === location.pathname && !isOpen}
                            key={route.path}
                        />
                    ))}
                <Box onClick={openProfile}>
                    <NavbarItem {...navbarItem} isActive={isOpen} />
                </Box>
            </List>
        </Box>
    );
}
