import { WrapItem, Avatar } from '@chakra-ui/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    src: string;
    name?: string;
    size?: string;
}

export default function ProfileAvatar({ src, name = '', size = '2xl', ...props }: Props) {
    return (
        <WrapItem>
            <LazyLoadImage
                style={{ height: '152px', objectFit: 'cover', borderRadius: '50%' }}
                src={src}
                height="100%"
                effect="blur"
            />
        </WrapItem>
    );
}
