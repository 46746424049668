import { beginCell } from '@ton/ton';
import { SendTransactionRequest } from '@tonconnect/ui-react';

export interface ICreateTransaction {
    address: string;
    amount: string;
    payload?: string;
}

export const createPayload = (str: string) => {
    const body = beginCell().storeUint(0, 32).storeStringTail(str).endCell();
    const payload = body.toBoc().toString('base64');

    return payload;
};

export const createTransaction = ({ address, amount, payload }: ICreateTransaction) => {
    const transaction: SendTransactionRequest = {
        messages: [
            {
                address,
                amount,
                payload: payload && createPayload(payload),
            },
        ],
        validUntil: Math.floor(Date.now() / 1000) + 360,
    };

    return transaction;
};
