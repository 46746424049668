import { Spinner } from '@chakra-ui/react';
import Colors from '../../config/colors';

interface Props {
    [key: string]: any;
}

export default function Loader({ ...props }: Props) {
    return (
        <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={Colors.blue}
            size="xl"
            {...props}
        />
    );
}
