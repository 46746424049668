import { useTonWallet } from '@tonconnect/ui-react';
import SteamLoginPage from '../steamLogin/SteamLoginPage';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function TonkeeperLoginPage() {
    const navigate = useNavigate();
    const wallet = useTonWallet();

    const nextPage = '/tonkeeper/steam/amount';

    useEffect(() => {
        if (!wallet) {
            navigate('/tonkeeper/auth');
        }
    }, []);

    return <SteamLoginPage nextPage={nextPage} />;
}
