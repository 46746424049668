import { Box, SimpleGrid } from '@chakra-ui/react';

import { IProductsCategory } from '../../interfaces';
import ProductCategoryCard from '../productCategoryCard/ProductCategoryCard';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { cardBasicVariants } from '../../config/animation';

interface Props {
    productsCategories: IProductsCategory[];
}

export default function ProductCategoryList({ productsCategories }: Props) {
    return (
        <Box display="flex" flexDirection="column" gap="3" fontWeight="500">
            <SimpleGrid spacing={4} templateColumns="repeat(auto-fit, minmax(145px, 1fr))">
                <AnimatePresence>
                    {productsCategories.map((productsCategory, index) => (
                        <motion.div
                            key={index}
                            variants={cardBasicVariants}
                            custom={index}
                            initial="hidden"
                            animate="visible">
                            <Link
                                to={`/subcategory/${productsCategory.id}`}
                                state={{ pageName: productsCategory.name }}
                                key={index}>
                                <ProductCategoryCard productsCategory={productsCategory} />
                            </Link>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </SimpleGrid>
        </Box>
    );
}
