import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
    accessToken: string | null;
}

const initialState: AuthState = {
    accessToken: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AuthState>) => {
            const { accessToken } = action.payload;

            localStorage.setItem('user', JSON.stringify({ accessToken: accessToken }));
            state.accessToken = accessToken;
        },
    },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
