import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalPayload, ModalState } from './interfaces';

const initialState: ModalState = {
    supportMessage: null,
};

export const showModal = createSlice({
    name: 'showModal',
    initialState,
    reducers: {
        setFieldSupportMessageShowModal(state, action: PayloadAction<ModalPayload>) {
            const { field, value } = action.payload;

            if (state.supportMessage) {
                state.supportMessage[field] = value;
            }
        },
        setSupportMessageShowModal(state, action: PayloadAction<ModalState>) {
            const { supportMessage } = action.payload;

            state.supportMessage = supportMessage;
        },
    },
});

export const supportMessageShowModalActions = showModal.actions;

export default showModal.reducer;
